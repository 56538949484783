import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ListItemText, MenuItem } from "@mui/material";
import { AsyncPaginate } from "react-select-async-paginate";

const StyledSelect = styled(AsyncPaginate)`
  > div {
    height: 51px;
    border-radius: 7px;
    cursor: pointer;
    color: #212121;
    font-weight: 500;
    border: 1px solid #939393;
    z-index: -10 !important;
    background: white;

    svg {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .dropdown-style {
    height: 225px !important;
    max-height: 225px !important;
    font-weight: 400;
    z-index: 1 !important;
    background: white;
    border: 1px solid #939393;

    > div {
      height: 100%;
    }
  }

  .dropdown-upward {
    bottom: 100%;
    transform: translateY(calc(-100% - 60px));
  }

  .dorpdown-downward {
    top: 100%;
    transform: translateY(0%);
  }
`;

const AsyncSelect = ({
  label,
  placeholder,
  value,
  setOpenModal,
  setValue,
  loadOptions,
  loadSelectedOption,
  disabled,
  exceptPatientId,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    if (isMenuOpen) {
      const elDropdown = document.querySelectorAll(
        '[id^="react-select-"][id$="-listbox"]'
      );
      if (elDropdown.length > 0) {
        const spaceAbove = elDropdown[0].getBoundingClientRect().top;
        const spaceBelow =
          window.innerHeight -
          elDropdown[0].getBoundingClientRect().bottom -
          100;

        if (
          spaceBelow >= elDropdown[0].clientHeight ||
          spaceBelow >= spaceAbove
        ) {
          elDropdown[0].classList.add("dropdown-style");
          elDropdown[0].classList.add("dropdown-downward");
          elDropdown[0].classList.remove("dropdown-upward");
        } else {
          elDropdown[0].classList.add("dropdown-style");
          elDropdown[0].classList.add("dropdown-downward");
          elDropdown[0].classList.remove("dropdown-upward");
        }
      }
    }
  }, [isMenuOpen]);

  useEffect(() => {
    loadSelectedOption(value)?.then((res) => {
      setSelectedValue(res);
    });
  }, [value]);

  const customOption = ({ innerProps, label, data }) => {
    if (data.value === exceptPatientId) {
      return "";
    }

    return (
      <MenuItem {...innerProps} value={data.value}>
        <ListItemText primary={label} />
      </MenuItem>
    );
  };

  return (
    <div className="select-async">
      <StyledSelect
        value={selectedValue}
        placeholder={placeholder}
        loadOptions={loadOptions}
        onChange={(e) => {
          setValue(e);
          setSelectedValue(e);
        }}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        additional={{
          page: 1,
        }}
        components={{
          Option: customOption,
        }}
        disabled={disabled}
      />
    </div>
  );
};

AsyncSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setOpenModal: PropTypes.func,
  setValue: PropTypes.func,
  loadOptions: PropTypes.func,
  loadSelectedOption: PropTypes.func,
  disabled: PropTypes.bool,
  exceptPatientId: PropTypes.string
};

AsyncSelect.defaultProps = {
  label: "",
  placholder: "",
  setOpenModal: () => {},
  setValue: () => {},
  loadOptions: () => {},
  loadSelectedOption: () => {},
  disabled: false,
  exceptPatientId: null
};

export default AsyncSelect;
