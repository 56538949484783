import React from "react";
import moment from "moment";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    width: 870,
    height: 1245,
    paddingTop: 35,
    paddingBottom: 35,
    paddingLeft: 50,
    paddingRight: 50,
  },
  title: {
    textAlign: "center",
    fontSize: "20px",
    textDecoration: "underline",
    fontWeight: "bold",
    marginBottom: "30px",
  },
  section: {
    flexGrow: 0,
    flexDirection: "row",
  },
  sections: {
    border: "1px solid black",
    marginTop: "20px",
  },
  endsection: {
    border: "1px solid black",
    marginTop: "5px",
  },
  flex: {
    flexGrow: 0,
    flexDirection: "row",
    height: "auto",
    justifyContent: "space-between",
  },
  text: {
    fontSize: "10px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  session: {
    width: "40%",
  },
  sessionCenter: {
    width: "20%",
  },
  table: {
    width: "100%",
  },
  paymentHeading: {
    fontSize: "10px",
    marginTop: "20px",
    marginBottom: "4px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    // border: '1px solid black',
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: "10px",
  },
  rowborder: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid black",
    paddingTop: 6,
    paddingBottom: 6,
    fontSize: "10px",
  },
  header: {
    borderTop: "1px solid black",
    fontSize: "11px",
    backgroundColor: "#b3b3b3",
    fontWeight: "bold",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  // So Declarative and unDRY 👌
  row1: {
    width: "16%",
    marginLeft: "10px",
  },
  row2: {
    width: "16%",
    marginLeft: "10px",
  },
  row3: {
    width: "16%",
    marginLeft: "10px",
  },
  row4: {
    width: "30%",
    marginLeft: "10px",
  },
  row5: {
    width: "23%",
    marginLeft: "10px",
  },
  row6: {
    width: "25%",
    marginLeft: "10px",
  },
  row7: {
    width: "20%",
    marginLeft: "10px",
  },
  row40: {
    width: "40%",
    marginLeft: "10px",
  },
  row40center: {
    width: "40%",
    textAlign: "center",
    marginLeft: "10px",
  },
  borderBottom: {
    borderBottom: "1px solid #ddd",
  },
  borderRight: {
    borderRight: "1px solid #ddd",
  },
});
const MyDoc = ({ payment, paymentMethods, date }) => {
  const today = moment(date);

  const currentPayments = payment?.filter((x) => {
    // if (moment(x.date).isBefore(today, "day")) {
    //   return false;
    // }

    if (x.paymentRecord?.length === 0) return true;

    // const sameDayPayment = x.paymentRecord?.filter((p) =>
    //   today.isSame(p.date, "day")
    // );
    // if (sameDayPayment?.length > 0) {
    //   return true;
    // } else {
    //   return false;
    // }

    return true;
  });

  const pastPayments = payment?.filter((x) => {
    // if (moment(x.date).isBefore(today, "day")) {
    //   return true;
    // }

    // const pastDayPayment = x.paymentRecord?.filter(
    //   (p) => !today.isSame(p.date, "day")
    // );
    // if (pastDayPayment?.length > 0) {
    //   return true;
    // } else {
    //   return false;
    // }

    if (x.paymentRecord?.length > 0) {
      const lastPayment = x.paymentRecord[x.paymentRecord.length - 1];
      if (Math.abs(lastPayment.payed) > Math.abs(x.gst)) {
        return true;
      }
    }

    return false;
  });

  const getOutstandingPayment = (values) => {
    const totalAmount = currentPayments.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.gst;
    }, 0);

    let totalPayment = 0;
    values?.map((item) => {
      if (item.paymentRecord?.length > 0) {
        totalPayment +=
          item.paymentRecord?.[item.paymentRecord.length - 1].payed;
      }
    });

    const total = totalAmount - totalPayment;

    return `${total.toLocaleString()}`;
  };

  const getTotalPayment = (values) => {
    let total = 0;
    values?.map((item) => {
      if (item.paymentRecord?.length > 0) {
        total += item.paymentRecord?.[item.paymentRecord.length - 1].payed;
      }
    });

    return `${total.toLocaleString()}`;
  };

  const getPayment = (data) => {
    if (data.paymentRecord?.length > 0) {
      const lastRecord = data.paymentRecord?.[data.paymentRecord.length - 1];
      return `$${lastRecord?.payed?.toLocaleString() || 0} - ${
        lastRecord?.paymentMethod
      }`;
    } else {
      return "$0";
    }
  };

  const getTotalMethodPayment = (values, method) => {
    let total = 0;

    values?.map((item) => {
      if (item.paymentRecord?.length > 0) {
        if (method) {
          item.paymentRecord?.map((paymentRecord) => {
            if (paymentRecord?.paymentMethod === method) {
              total += paymentRecord?.currentPaidAmount ?? 0;
            }
          })
        } else {
          total += item.paymentRecord?.[item?.paymentRecord?.length - 1].payed;
        }
      }
    });

    return `${total.toLocaleString()}`;
  };

  const handleTotalAmount = (currentPayments) => {
    const totalAmount = currentPayments.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.totalAmount;
    }, 0);
    const formattedTotalAmount = totalAmount.toLocaleString();
    return formattedTotalAmount;
  };

  const handleGstTotalAmount = (currentPayments) => {
    const totalAmount = currentPayments.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.gst;
    }, 0);
    const formattedTotalAmount = totalAmount.toLocaleString();
    return formattedTotalAmount;
  };

  return (
    <Document>
      <Page break={true} style={styles.page}>
        <View style={styles.title}>
          <Text>Session Details Report</Text>
        </View>
        <View style={styles.section}>
          <View style={styles?.session}>
            <View style={styles.flex}>
              <Text style={styles.text}>Start Date: </Text>
              <Text style={styles.text}>
                {moment(payment[0]?.session.date).format("DD-MMM-YYYY") +
                  " " +
                  payment[0]?.session.startTime}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.text}>End Date: </Text>
              <Text style={styles.text}>
                {today.format("DD-MMM-YYYY HH:mm")}
              </Text>
            </View>
          </View>
          <View style={styles?.sessionCenter}></View>
          <View style={styles?.session}>
            <View style={styles.flex}>
              <Text style={styles.text}>Total Visit: </Text>
              <Text style={styles.text}>{payment?.length}</Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.text}>Outstanding Payment: </Text>
              <Text style={styles.text}>
                ${getOutstandingPayment(currentPayments)}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.text}>Total Charges: </Text>
              {/* <Text style={styles.text}>${data.totalAmount}</Text> */}
              <Text style={styles.text}>${handleGstTotalAmount(payment)}</Text>
            </View>
          </View>
        </View>
        <View>
          <View style={styles.endsection}>
            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.row1}>Invoice No</Text>
                <Text style={styles.row2}>Total Amount</Text>
                <Text style={styles.row3}>GST</Text>
                <Text style={styles.row4}>Payer Name</Text>
                <Text style={styles.row5}>Staff</Text>
                <Text style={styles.row6}>Payment</Text>
              </View>
              {currentPayments?.map((data, index) => (
                <View
                  style={{
                    ...styles.row,
                    ...(index === currentPayments?.length - 1
                      ? {}
                      : styles.borderBottom),
                  }}
                  key={index}
                >
                  <Text style={{ ...styles.row1, ...styles.borderRight }}>
                    {`IV-${data.invoiceNo || 0}`}
                  </Text>
                  <Text style={{ ...styles.row2, ...styles.borderRight }}>
                    ${data.totalAmount?.toLocaleString()}
                  </Text>
                  <Text style={{ ...styles.row3, ...styles.borderRight }}>
                    ${data.gst?.toLocaleString() || "$0"}
                  </Text>
                  <Text style={{ ...styles.row4, ...styles.borderRight }}>
                    {
                      data?.session.appointment?.patient?.patientProfile
                        ?.fullName
                    }{" "}
                  </Text>
                  <Text style={styles.row5}>
                    {data?.session?.staff
                      ?.map((staff) => `${staff?.username}`)
                      .join(", ") ?? "-"}
                  </Text>
                  <View style={styles.row6}>
                    {
                      data?.paymentRecord?.map((paymentRecord) => (
                        <Text key={index}>${paymentRecord.currentPaidAmount} - {paymentRecord.paymentMethod}</Text>
                      ))
                    }
                  </View>
                </View> 
              ))}
            </View>
          </View>
          <View style={{ ...styles.row, borderBottom: "1px solid black" }}>
            <Text style={{ ...styles.row1, ...styles.bold }}>Total</Text>
            <Text style={styles.row2}>
              ${handleTotalAmount(currentPayments)}
            </Text>
            <Text style={styles.row3}>
              ${handleGstTotalAmount(currentPayments)}
            </Text>
            <Text style={styles.row4}></Text>
            <Text style={styles.row5}></Text>
            <Text style={styles.row6}>${getTotalPayment(currentPayments)}</Text>
          </View>
        </View>

        <Text style={{ ...styles.paymentHeading, ...styles.bold }}>
          Payment Collections for Past Invoices
        </Text>
        <View>
          <View style={styles.endsection}>
            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.row1}>Invoice No</Text>
                <Text style={styles.row2}>Total Amount</Text>
                <Text style={styles.row3}>GST</Text>
                <Text style={styles.row4}>Payer Name</Text>
                <Text style={styles.row5}>Staff</Text>
                <Text style={styles.row6}>Payment</Text>
              </View>
              {pastPayments?.map((data, index) => (
                <View
                  style={{
                    ...styles.row,
                    ...(index === pastPayments?.length - 1
                      ? {}
                      : styles.borderBottom),
                  }}
                  key={index}
                >
                  <Text style={{ ...styles.row1, ...styles.borderRight }}>
                    {`IV-${data.invoiceNo || 0}`}
                  </Text>
                  <Text style={{ ...styles.row2, ...styles.borderRight }}>
                    ${data.totalAmount?.toLocaleString()}
                  </Text>
                  <Text style={{ ...styles.row3, ...styles.borderRight }}>
                    ${data.gst?.toLocaleString() || "$0"}
                  </Text>
                  <Text style={{ ...styles.row4, ...styles.borderRight }}>
                    {
                      data?.session.appointment?.patient?.patientProfile
                        ?.fullName
                    }{" "}
                    {/* ({data?.session.appointment?.patient?.accountNumber ?? "-"}) */}
                  </Text>
                  <Text style={styles.row5}>
                    {data?.session?.staff
                      ?.map((staff) => `${staff?.username}`)
                      .join(", ") ?? "-"}
                  </Text>
                  <View style={styles.row6}>
                    {
                      data?.paymentRecord?.map((paymentRecord) => (
                        <Text key={index}>${paymentRecord.currentPaidAmount} - {paymentRecord.paymentMethod}</Text>
                      ))
                    }
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={{ ...styles.row, borderBottom: "1px solid black" }}>
            <Text style={{ ...styles.row1, ...styles.bold }}>Total</Text>
            <Text style={styles.row2}>
              ${handleTotalAmount(pastPayments)}
            </Text>
            <Text style={styles.row3}>
              ${handleGstTotalAmount(pastPayments)}
            </Text>
            <Text style={styles.row4}></Text>
            <Text style={styles.row5}></Text>
            <Text style={styles.row6}>${getTotalPayment(pastPayments)}</Text>
          </View>
        </View>

        <Text style={{ ...styles.paymentHeading, ...styles.bold }}>
          Payment Summary
        </Text>
        <View style={styles.endsection}>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.row40}>Mode</Text>
              <Text style={styles.row6}>This Session</Text>
              <Text style={styles.row6}>Past Session</Text>
              <Text style={styles.row6}>Sub Total</Text>
            </View>
            {paymentMethods?.map((item, index) => (
              <View
                style={{
                  ...styles.row,
                  ...(index === paymentMethods?.length - 1
                    ? {}
                    : styles.borderBottom),
                }}
              >
                <Text style={{ ...styles.row40, ...styles.borderRight }}>
                  {item.name}
                </Text>
                <Text style={{ ...styles.row6, ...styles.borderRight }}>
                  ${getTotalMethodPayment(currentPayments, item.name)}
                </Text>
                <Text style={{ ...styles.row6, ...styles.borderRight }}>
                  ${getTotalMethodPayment(pastPayments, item.name)}
                </Text>
                <Text style={styles.row6}>
                  ${getTotalMethodPayment(payment, item.name)}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={{ ...styles.row, borderBottom: "1px solid black" }}>
          <Text style={{ ...styles.row40, ...styles.bold }}>Total</Text>
          <Text style={styles.row6}>
            ${getTotalMethodPayment(currentPayments)}
          </Text>
          <Text style={styles.row6}>
            ${getTotalMethodPayment(pastPayments)}
          </Text>
          <Text style={styles.row6}>${getTotalMethodPayment(payment)}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default MyDoc;
