import React, { useState, useEffect } from "react";
import Query from "devextreme/data/query";
import { Tooltip } from "react-tooltip";
// import localization from 'devextreme/localization';
// import { moviesData } from "./data.js";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Tag } from "../../../assets/images/Tag.svg";
import chroma from "chroma-js";
import {
  getFormatedTime,
  adjustDateFormat,
} from "../../../helperFunctions/utils";
import { setPatientData } from "../../../redux/slices/patientSlice";
import {
  I_SS_Session,
  I_SS_Billing,
  A_SS_Session,
} from "../../../assets/images";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

// function getMovieById(id) {
// 	return Query(moviesData).filter(["id", id]).toArray()[0];
// }
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { setData } from "../../../redux/slices/StaffFormsSlices";
import { getSessionByAppointment } from "../../../apis/session";
import moment from "moment";
import { getAppointment, getEditBy } from "../../../apis/appointment";
import { toastify } from "../../../helperFunctions/toastify";
import { authorizedRequests } from "../../../axios/axios-config";

export default function Appointment({
  model,
  statusSession,
  isPayed,
  setIsModalOpen,
  statusRostering,
  setRefreshAppointments,
  setRefreshWaiting
}) {
  const [deletionModal, setDeletionModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const iconsStyle = {
    position: "absolute",
    background: "rgb(11,21,114,0.3)",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const { targetedAppointmentData, appointmentData } = model.data;

  const requiredData = useSelector((state) => {
    return state.staffForm.rosteringCardData.find(
      (o) => o?._id == targetedAppointmentData?.id
    );
  });

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: appointmentData.id,
      type: "Appointment",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  // const movieData = getMovieById(targetedAppointmentData.movieId) || {};
  // const hexColor = requiredData?.tag?.color || "#E4DDFC";
  let svgColor;
  if (appointmentData?.locationId == 0) {
    svgColor = statusRostering?.WaitingList
      ? statusRostering?.WaitingList
      : "#ffffff";
  }
  if (targetedAppointmentData?.status == "Planned") {
    svgColor = statusRostering?.AppointmentPlanned
      ? statusRostering?.AppointmentPlanned
      : "#ffffff";
  }
  if (targetedAppointmentData?.status == "Confirmed") {
    svgColor = statusRostering?.AppointmentConfirmed
      ? statusRostering?.AppointmentConfirmed
      : "#ffffff";
  }
  if (targetedAppointmentData?.status === "Cancelled") {
    svgColor = statusRostering?.AppointmentCancelled
      ? statusRostering?.AppointmentCancelled
      : "#ffffff";
  }
  if (statusSession === "Pending") {
    svgColor = statusRostering?.OnSession
      ? statusRostering?.OnSession
      : "#ffffff";
  }
  if (isPayed === true) {
    svgColor = statusRostering?.BillingPaid
      ? statusRostering?.BillingPaid
      : "#ffffff";
  }

  let darkHexColor = "rgba(11, 21, 114, 0.3)";

  const handleDeleteAppointment = async () => {
    const { result, error } = await authorizedRequests({
      url: "appointment/updateAppointment",
      method: "post",
      data: {
        isDeleted: true,
        dataId: requiredData?._id,
      },
    });
    if (error) {
      toastify("Failed to delete this appointment", "error");
      return;
    }
    toastify("Appointment deleted successfully", "success");
    setRefreshAppointments((val) => !val);
    return;
  };

  const handleConfirmAppointment = async () => {
    const { result, error } = await authorizedRequests({
      url: "appointment/updateAppointment",
      method: "post",
      data: {
        status: "Confirmed",
        dataId: requiredData?._id,
      },
    });
    if (error) {
      toastify("Failed to delete this appointment", "error");
      return;
    }
    toastify("Appointment confirmed successfully", "success");
    setRefreshAppointments((val) => !val);
    return;
  };

  return (
    <React.Fragment>
      {requiredData?.otherTags?.map((otherTag, index) => (
        <div
          key={index}
          style={{ backgroundColor: otherTag.color, height: "4px" }}
        ></div>
      ))}
      <div
        className="showtime-preview p-2 h-100"
        style={{
          color: "black",
        }}
        id={`appointmentTooltip${model?.data?.appointmentData?.id}`}
      >
        <div className="d-flex">
          <div
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            {requiredData?.status ? (
              <strong>
                {/* {requiredData?.patient?.patientProfile?.fullName} ({requiredData?.patient?.isMember ? "M" : "NM"}) ({requiredData?.patient?.accountNumber}), 
									{` ${requiredData?.patient?.patientProfile?.contactNo ?? ""}`} */}
                {requiredData?.patient?.patientProfile?.fullName} (
                {requiredData?.patient?.isMember ? "M" : "NM"}),
                {` ${requiredData?.patient?.patientProfile?.contactNo ?? ""}`}
              </strong>
            ) : (
              <strong>
                {requiredData?.patientName}, {requiredData?.contactNo}
              </strong>
            )}
          </div>
          {appointmentData?.locationId !== 0 && (
            <>
              <div
                className="rostering-icon"
                style={{
                  ...iconsStyle,
                  display: "none",
                  right: "38px",
                }}
                onClick={async (e) => {
                  e.stopPropagation();
                  // setIsModalOpen(false);
                  const { result, error } = await getSessionByAppointment(
                    requiredData?._id ? requiredData?._id : ""
                  );
                  if (error) {
                    navigate("/add-session", {
                      state: {
                        stateDate: requiredData.date,
                        valueObj: {
                          appointment: requiredData?._id,
                          startTime: requiredData.startTime,
                          branch: requiredData.branch?._id,
                          endTime: requiredData.endTime,
                          date: requiredData.date,
                          staff: requiredData?.completeStaffList?.map(
                            (o) => o._id
                          ),
                          formStatus: "New",
                        },
                      },
                    });
                  } else if (result) {
                    navigate("/add-session", {
                      state: {
                        stateDate: result?.data?.date,
                        valueObj: {
                          dataId: result?.data?._id,
                          appointment: requiredData?._id,
                          startTime: result?.data?.startTime,
                          branch: result?.data?.branch?._id,
                          endTime: result?.data?.endTime,
                          room: result?.data?.room,
                          date: result?.data?.date,
                          staff: result?.data?.staff?.map((o) => o._id),
                          formStatus: "Edit",
                        },
                      },
                    });
                  }
                }}
              >
                <img src={I_SS_Session} alt="session" />
              </div>
              <div
                className="rostering-icon session-icon"
                style={{
                  ...iconsStyle,
                  right: "72px",
                  display: "none",
                }}
                onClick={async (e) => {
                  e.stopPropagation();

                  const result = await getAppointment(appointmentData.id);
                  if (result) {
                    dispatch(setPatientData(result.patient));
                    navigate("/add-patient");
                  }

                  //  navigate("/patient-details", {
                  //    state: {
                  //      session: result?.data,
                  //    },
                  //  });
                  // e.stopPropagation();
                  // // setIsModalOpen(false);

                  // const { result, error } = await getSessionByAppointment(
                  //   requiredData?._id
                  // );
                  // if (error) {
                  //   toastify(
                  //     error.message ??
                  //       "Failed to found sesssion for this appointment",
                  //     "error"
                  //   );
                  //   return;
                  // } else {
                  //   navigate("/add-billing", {
                  //     state: {
                  //       session: result?.data,
                  //     },
                  //   });
                  // }
                }}
              >
                <img src={I_SS_Billing} alt="billing" />
              </div>
              <div
                className="rostering-icon"
                style={{
                  ...iconsStyle,
                  display: "none",
                  right: "105px",
                }}
                onClick={async (e) => {
                  e.stopPropagation();
                  setDeletionModal(true);
                }}
              >
                <i className="fa fa-trash"></i>
              </div>

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmationModal(true);
                }}
                style={{
                  ...iconsStyle,
                  right: "5px",
                  background: darkHexColor,
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.9902 2.46912C18.9893 1.82721 18.7342 1.21168 18.2806 0.756531C17.827 0.301385 17.2115 0.0434888 16.5682 0.0389961L10.6046 5.56904e-05C10.2821 -0.00212167 9.96228 0.0595683 9.66376 0.181554C9.36525 0.30354 9.09395 0.483399 8.86558 0.710717L0.716573 8.82005C0.489635 9.04592 0.30954 9.31421 0.186585 9.60958C0.0636303 9.90495 0.000227135 10.2216 6.0915e-07 10.5414C-0.000225917 10.8613 0.0627287 11.178 0.185265 11.4736C0.307801 11.7691 0.487516 12.0376 0.714134 12.2638L6.74966 18.2874C7.20706 18.7437 7.82734 19 8.4741 19C9.12086 19 9.74114 18.7437 10.1985 18.2874L18.2853 10.217C18.5123 9.99065 18.6922 9.72182 18.8149 9.42593C18.9375 9.13003 19.0004 8.8129 19 8.49271L18.9902 2.46912ZM14.1047 6.07962C13.7813 6.07962 13.4711 5.95141 13.2424 5.7232C13.0137 5.49499 12.8852 5.18547 12.8852 4.86273C12.8852 4.54 13.0137 4.23048 13.2424 4.00227C13.4711 3.77406 13.7813 3.64585 14.1047 3.64585C14.4282 3.64585 14.7384 3.77406 14.9671 4.00227C15.1958 4.23048 15.3243 4.54 15.3243 4.86273C15.3243 5.18547 15.1958 5.49499 14.9671 5.7232C14.7384 5.95141 14.4282 6.07962 14.1047 6.07962Z"
                    fill={svgColor}
                  />
                </svg>
              </div>
            </>
          )}
          {appointmentData?.locationId == 0 && (
            <>
              <div
                className="rostering-icon"
                style={{
                  ...iconsStyle,
                  display: "none",
                  right: "10px",
                }}
                onClick={async (e) => {
                  e.stopPropagation();

                  const { result, error } = await authorizedRequests({
                    url: "waitingList/updateWaitingList",
                    method: "post",
                    data: {
                      isDeleted: true,
                      dataId: requiredData?._id,
                    },
                  });
                  if (error) {
                    toastify("Failed to delete this waiting", "error");
                    return;
                  }
                  toastify("Waiting deleted successfully", "success");
                  setRefreshAppointments((val) => !val);
                  setRefreshWaiting((val) => !val);
                  return;
                }}
              >
                <i className="fa fa-trash"></i>
              </div>
            </>
          )}
        </div>
        <div>
          <div>
            {getFormatedTime(requiredData?.startTime)} -{" "}
            {getFormatedTime(requiredData?.endTime)}
          </div>
        </div>
        {/* <strong>Staff:</strong> */}
        {/* <ul className="mt-2 ps-0">
					{requiredData?.completeStaffList?.length > 0 &&
						requiredData?.completeStaffList?.map((o) => {
							return (
								<>
									<li>{o?.username}</li>
								</>
							);
						})}
				</ul> */}
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: requiredData?.remarks?.replaceAll(/\n/g, "<br />"),
            }}
          />
        </div>
        <br />

        <Modal
          centered
          show={deletionModal}
          onHide={() => {
            setDeletionModal(false);
          }}
        >
          <Modal.Body className="font-18">
            Are you sure want to delete appointment ?
            <div className="align-self-end m-3 d-flex">
              <Button
                className="me-3 delete-no ms-auto"
                onClick={() => {
                  setDeletionModal(false);
                }}
              >
                No
              </Button>
              <Button
                className="delete-yes"
                onClick={() => {
                  handleDeleteAppointment();
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          centered
          show={confirmationModal}
          onHide={() => {
            setConfirmationModal(false);
          }}
        >
          <Modal.Body className="font-18">
            Are you sure want to confirm this appointment ?
            <div className="align-self-end m-3 d-flex">
              <Button
                className="me-3 delete-no ms-auto"
                onClick={() => {
                  setConfirmationModal(false);
                }}
              >
                No
              </Button>
              <Button
                className="delete-yes"
                onClick={() => {
                  handleConfirmAppointment();
                }}
              >
                Yes
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {model?.data?.appointmentData?.locationId !== 0 && (
        <Tooltip
          anchorSelect={`#appointmentTooltip${model?.data?.appointmentData?.id}`}
          place="right"
          className="z-[1000]"
        >
          <div>
            {model?.data?.appointmentData?.status}
            <br />
            <strong>
              {`${requiredData?.patient?.patientProfile?.fullName} (
                  ${requiredData?.patient?.isMember ? "M" : "NM"}),
                  ${requiredData?.patient?.patientProfile?.contactNo ?? ""}`}
            </strong>

            <br />
            <div>
              <div>
                {getFormatedTime(requiredData?.startTime)} -
                {getFormatedTime(requiredData?.endTime)}
              </div>
            </div>

            <br />
            {editLogs ? (
              <div>
                By {editLogs?.userName ?? "-"} At{" "}
                {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
              </div>
            ) : (
              <div>
                By {requiredData?.createdBy ? requiredData?.createdBy : "-"}
              </div>
            )}
          </div>
        </Tooltip>
      )}
    </React.Fragment>
  );
}
