import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { calcTotalWithDisc } from "../../../helperFunctions/utils";
import { ExpandableIcon } from "../../../assets/images/index";

export default function PackagesTable({
  isConversion,
  isEdit,
  sessionInvoices,
  setSessionInvoices,
  setPromo,
  setSubTotal,
  setTotal,
  setTax,
  setUsePoint,
  onRowEdit,
}) {
	const numberFormatter = Intl.NumberFormat('en-US');

  const [activeIndex, setActiveIndex] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [itemToDel, setItemToDel] = useState();

  const getTotalPrice = (product, e) => {
    const totalPrice = product.ori_total_price * e.qty;
    if (!isConversion) {
      return product.total_price;
    }

    if (isConversion && !e._id) {
      return product.total_price;
    }

    return totalPrice;
  }

  const calculateBalancePrice = (totalPrice, balanceQty, totalQty, packageQty, isExisting) => {
    if (!isConversion) {
      return totalPrice;
    }

    if (isConversion && !isExisting) {
      return totalPrice;
    }
    // if (!isConversion || !isExisting) {
    //   return (totalPrice / totalQty) * balanceQty;
    // }

    const balance_price = (totalPrice / totalQty) * (balanceQty);
    return balance_price;
  }

  const calculateTotalItemPackage = (session, isExisting) => {
    let totalItem = 0;
    session?.treatments?.map((oTreatment) => {
      totalItem += oTreatment.new_price ? Number(oTreatment.new_price) : Number(oTreatment.total_price)
    });

    session?.products?.map((oProduct) => {
      totalItem += oProduct.new_price ? Number(oProduct.new_price) : Number(oProduct.total_price)
    });

    if (!isConversion || !isExisting) {
      return totalItem;
    }

    return totalItem * -1;
  }

  const calculateTotalPackagePrice = (session, isExisting) => {
    console.log("DEBUG", session)
    // if (newDiscType === "Percentage") {
    //   const totalPrice = calcTotalWithDisc(
    //     totalItemPrice,
    //     promoDiscountValue,
    //     promoDiscountType
    //   );

    //   const disc_price = totalPrice * (1 - newDisc / 100);
    //   return disc_price;
    // } else {
    //   const disc_price =
    //     calcTotalWithDisc(
    //       totalItemPrice,
    //       promoDiscountValue,
    //       promoDiscountType
    //     );

    //   if (disc_price < 0) {
    //     return disc_price + Number(newDisc);
    //   } else {
    //     return disc_price - Number(newDisc);
    //   }
    // }
    
    if (session.discountType === "Percentage") {
      const totalPrice = calcTotalWithDisc(
        session.price,
        session.promoDiscountValue,
        session.promoDiscountType
      );
      const disc_price = totalPrice * (1 - Number(session.discount) / 100);

      if (disc_price < 0) {
        return 0;
      }

      if (!isConversion || !isExisting) {
        return disc_price;
      }

      return disc_price * -1;
    } else {
      const disc_price =
        calcTotalWithDisc(
          session.price,
          session.promoDiscountValue,
          session.promoDiscountType
        );
      
      let total_disc_price = 0;
      if (disc_price < 0) {
        total_disc_price = disc_price + Number(session.discount);
      } else {
        total_disc_price = disc_price - Number(session.discount);
      }

      if (total_disc_price < 0) {
        return 0;
      }

      if (!isConversion || !isExisting) {
        return total_disc_price;
      }

      return total_disc_price * -1;
    }
  }

  if (sessionInvoices?.filter((o) => o.type == "PACKAGE")?.length == 0)
    return (
      <div className="w-100 text-center" style={{ padding: "24px" }}>
        There are no records to display
      </div>
    );
  return (
    <>
      <Table>
        <thead
          style={{
            background: "#34556F",
            color: "white",
          }}
        >
          <tr
            style={{
              height: "45px",
            }}
            className={`align-items-center`}
          >
            <th style={{ textAlign: "center", width: "200px" }}>
              <span className="me-2 ps-2">Item Description</span>
            </th>
            <th style={{ textAlign: "center" }}>
              <span className="me-2 ps-2">Quantity</span>
            </th>
            <th style={{ textAlign: "center" }}>
              <span className="me-2">Promo</span>
            </th>
            <th style={{ textAlign: "center" }}>
              <span className="me-2">Discount</span>
            </th>
            {/* <th>Limit</th> */}

            <th style={{ textAlign: "center" }}>Amount</th>
            <th style={{ width: "200px", textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {sessionInvoices.length > 0 &&
            sessionInvoices?.map((e, index) => {
              if (e.type !== "PACKAGE") {
                return <></>;
              }
              return (
                <>
                  <tr
                    style={{
                      backgroundColor: e.isAlreadyPaid ? "#fff9e5" : "inherit",
                    }}
                    // style={
                    // 	e.isAlreadyPaid
                    // 		? { backgroundColor: "#B6ee7a" }
                    // 		: {}
                    // }
                  >
                    <td className="ps-3" style={{ textAlign: "center" }}>
                      {e.isAlreadyPaid ? "Current Package - " : ""}
                      {e.name}
                    </td>
                    <td style={{ textAlign: "center" }}>{e?.qty}</td>
                    <td style={{ textAlign: "center" }}>
                      {e.promoDiscountType === "Percentage"
                        ? `${e.promoDiscountValue ? e.promoDiscountValue : 0}%`
                        : `$${e.promoDiscountValue ? e.promoDiscountValue : 0}`}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {
                        e.discountType ? (
                          e.discountType === "Percentage"
                            ? `${e.discount}%`
                            : `$${e.discount}`
                        ) : (
                          e.package_discountType === "Percentage"
                            ? `${e.discount}%`
                            : `$${e.discount}`
                        )
                      }
                    </td>
                    {/* <td className="wd-100">{e.qty}</td> */}
                    <td style={{ textAlign: "center" }}>${numberFormatter.format(e.total_price)}</td>
                    <td style={{ width: "200px" }}>
                      <div className="d-flex ">
                        {
                          (isConversion && isEdit) ? (
                            ""
                          ) : (
                            <>
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  onRowEdit(
                                    index,
                                    e.qty,
                                    e.discount,
                                    e.discountType ? e.discountType : e.package_discountType,
                                    e.price,
                                    e.remark
                                  );
                                }}
                              >
                                <i className="fa fa-pen"></i>
                              </div>
                            </>
                          )
                        }
                      
                        {
                          (isConversion && isEdit) ? (
                            ""
                          ) : (
                            <div
                              className="ms-3  cursor-pointer icon-width"
                              onClick={() => {
                                setItemToDel(index);
                                setOpenModal(true);
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </div>
                          )
                        }
                        <div className="ms-2 cursor-pointer icon-width">
                          <img
                            src={ExpandableIcon}
                            className="expand-icon"
                            onClick={(e) => {
                              if (activeIndex == index) {
                                setActiveIndex();
                                return;
                              }
                              setActiveIndex(index);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  {activeIndex === index && (
                    <>
                      <tr>
                        <td
                          colSpan="12"
                          style={{
                            backgroundColor: "#f2f2f2",
                            padding: "20px",
                          }}
                        >
                          <Table>
                            <thead
                              style={{
                                background: "#34556F",
                                color: "white",
                                width: "100%",
                              }}
                            >
                              <tr
                                style={{
                                  height: "45px",
                                }}
                                className="align-items-center
							"
                              >
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  <span className="me-2 ps-2">Type</span>
                                </th>

                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Name
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Quantity
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Used
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Balance
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {e.products?.length > 0 &&
                                e?.products?.map((product, productIndex) => {
                                  const finalQty =
                                    product.qty * (e.qty ? e.qty : 1);

                                  return (
                                    <>
                                      <tr>
                                        <td style={{ textAlign: "center" }}>
                                          Product
                                        </td>

                                        <td style={{ textAlign: "center" }}>
                                          {product.name}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {Math.abs(finalQty)}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <div
                                            className="d-flex"
                                            style={{ justifyContent: "center" }}
                                          >
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                let tempSessions = [
                                                  ...sessionInvoices,
                                                ];
                                                let tempArr =
                                                  tempSessions[index]
                                                    ?.products;
                                                if (
                                                  tempArr[productIndex].used ==
                                                  0
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used -
                                                  1;

                                                const balanceQty = (finalQty < 0
                                                  ? finalQty + product.used
                                                  : finalQty - product.used);

                                                const product_price = getTotalPrice(product, e);
                                                const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                tempArr[
                                                  productIndex
                                                ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                
                                                if (isConversion) {
                                                  const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                  tempSessions[index].price = totalItemPrice;
                                                  const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                  tempSessions[index].total_price = totalPackagePrice;
                                                }

                                                tempSessions[index].products =
                                                  [...tempArr];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-minus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <span className="mx-2">
                                              {product.used}
                                            </span>
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                let tempSessions = [
                                                  ...sessionInvoices,
                                                ];
                                                let tempArr =
                                                  tempSessions[index]
                                                    ?.products;

                                                if (
                                                  tempArr[productIndex].used ==
                                                  Math.abs(finalQty)
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used +
                                                  1;
                                                
                                                const balanceQty = (finalQty < 0
                                                  ? finalQty + product.used
                                                  : finalQty - product.used);
                                                const product_price = getTotalPrice(product, e);
                                                const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                tempArr[
                                                  productIndex
                                                ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);

                                                
                                                if (isConversion) {
                                                  const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                  tempSessions[index].price = totalItemPrice;
                                                  const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                  tempSessions[index].total_price = totalPackagePrice;
                                                }

                                                tempSessions[index].products =
                                                  [...tempArr];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-plus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {finalQty < 0
                                            ? finalQty + product.used
                                            : finalQty - product.used}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {
                                            (!isConversion || (isConversion && !e._id)) ? (
                                              product?.new_price > 0
                                                ? product?.new_price
                                                : product?.new_price ??
                                                  product?.total_price
                                            ) : (
                                              <input
                                                type="number"
                                                style={{
                                                  width: "60px",
                                                  background: "transparent",
                                                  justifyContent: "center",
                                                  borderRadius: "5px"
                                                }}
                                                value={
                                                  product?.new_price > 0
                                                    ? product?.new_price
                                                    : product?.new_price ??
                                                      product?.total_price
                                                }
                                                onChange={(event) => {
                                                  let tempSessions = [
                                                    ...sessionInvoices,
                                                  ];
                                                  let tempArr =
                                                    tempSessions[index]?.products;
    
                                                  tempArr[productIndex].new_price =
                                                    event?.target?.value;

                                                  if (isConversion) {
                                                    const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                    tempSessions[index].price = totalItemPrice;
                                                    const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                    tempSessions[index].total_price = totalPackagePrice;
                                                  }
    
                                                  tempSessions[index].products = [...tempArr];
                                                  setSessionInvoices([
                                                    ...tempSessions,
                                                  ]);
                                                }}
                                              />
                                            )
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              {e.treatments?.length > 0 &&
                                e?.treatments?.map((product, productIndex) => {
                                  const finalQty =
                                    product.qty * (e.qty ? e.qty : 1);

                                  return (
                                    <>
                                      <tr>
                                        <td style={{ textAlign: "center" }}>
                                          Treatment
                                        </td>
                                        {/* <td>
																						{
																							product.name
																						}
																					</td> */}
                                        <td style={{ textAlign: "center" }}>
                                          {product.name}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {Math.abs(finalQty)}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <div
                                            className="d-flex"
                                            style={{ justifyContent: "center" }}
                                          >
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                let tempSessions = [
                                                  ...sessionInvoices,
                                                ];
                                                let tempArr =
                                                  tempSessions[index]
                                                    ?.treatments;
                                                if (
                                                  tempArr[productIndex].used ==
                                                  0
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used -
                                                  1;

                                                const balanceQty = (finalQty < 0
                                                  ? finalQty + product.used
                                                  : finalQty - product.used);

                                                const product_price = getTotalPrice(product, e);
                                                const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                tempArr[
                                                  productIndex
                                                ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);
                                                
                                                if (isConversion) {
                                                  const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                  tempSessions[index].price = totalItemPrice;

                                                  const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                  tempSessions[index].total_price = totalPackagePrice;
                                                }

                                                tempSessions[index].treatments =
                                                  [...tempArr];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-minus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <span className="mx-2">
                                              {product.used}
                                            </span>
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                let tempSessions = [
                                                  ...sessionInvoices,
                                                ];
                                                let tempArr =
                                                  tempSessions[index]
                                                    ?.treatments;

                                                if (
                                                  tempArr[productIndex].used ==
                                                  Math.abs(finalQty)
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used +
                                                  1;

                                                const balanceQty = (finalQty < 0
                                                  ? finalQty + product.used
                                                  : finalQty - product.used);
                                                const product_price = getTotalPrice(product, e);
                                                const calculatedValue = calculateBalancePrice(product_price, balanceQty, finalQty, e.qty, e._id);

                                                tempArr[
                                                  productIndex
                                                ].new_price = calculatedValue % 1 === 0 ? calculatedValue.toString() : calculatedValue.toFixed(2);

                                                
                                                if (isConversion) {
                                                  const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                  tempSessions[index].price = totalItemPrice;
                                                  const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                  tempSessions[index].total_price = totalPackagePrice;
                                                }

                                                tempSessions[index].treatments =
                                                  [...tempArr];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-plus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {finalQty < 0
                                            ? finalQty + product.used
                                            : finalQty - product.used}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {
                                            (!isConversion || (isConversion && !e._id)) ? (
                                              product?.new_price > 0
                                                ? product?.new_price
                                                : product?.new_price ??
                                                  product?.total_price
                                            ) : (
                                              <input
                                                type="number"
                                                style={{
                                                  width: "60px",
                                                  background: "transparent",
                                                  justifyContent: "center",
                                                  borderRadius: "5px"
                                                }}
                                                value={
                                                  product?.new_price > 0
                                                    ? product?.new_price
                                                    : product?.new_price ??
                                                      product?.total_price
                                                }
                                                onChange={(event) => {
                                                  let tempSessions = [
                                                    ...sessionInvoices,
                                                  ];
                                                  let tempArr =
                                                    tempSessions[index]?.treatments;
    
                                                  tempArr[productIndex].new_price =
                                                    event?.target?.value;  
                                                  
                                                  if (isConversion) {
                                                    const totalItemPrice = calculateTotalItemPackage(tempSessions[index], e._id);
                                                    tempSessions[index].price = totalItemPrice;
                                                    const totalPackagePrice = calculateTotalPackagePrice(tempSessions[index], e._id);
                                                    tempSessions[index].total_price = totalPackagePrice;
                                                  }
    
                                                  tempSessions[index].treatments = [
                                                    ...tempArr,
                                                  ];
                                                  setSessionInvoices([
                                                    ...tempSessions,
                                                  ]);
                                                }}
                                              />
                                            )
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              );
            })}
        </tbody>
      </Table>
      <Modal
        centered
        show={openModal}
        onHide={() => {
          setItemToDel(null);
          setOpenModal(!openModal);
        }}
      >
        <Modal.Body className="font-18">
          Confirm Item Deletion
          <div className="align-self-end m-3 d-flex">
            <Button
              className="me-3 delete-no ms-auto"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              No
            </Button>
            <Button
              className="delete-yes"
              onClick={() => {
                if (sessionInvoices?.length == 1) {
                  setSubTotal(0);
                  setPromo(0);
                  setTotal(0);
                  setTax(null);
                  setUsePoint(0);
                }
                let tempArr = [...sessionInvoices];
                tempArr = tempArr.filter((k, idx) => idx !== itemToDel);
                setSessionInvoices([...tempArr]);
                setOpenModal(false);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
