import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import SelectComponent from "../../SharedComponents/SelectComponent";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Modal, Button, Form } from "react-bootstrap";
import {
  getMediaResponseReport,
  getMediaResponseDetail,
} from "../../../apis/dashboardReports";
import {
  getMonthFromRange,
  getShortMonth,
  getRandomColor,
  formatDate,
  filterTypes,
  getFilterDates,
} from "../../../helperFunctions/utils";
import { thousandAndDecimalFormat } from "../../../utils/common";

import DashboardTable from "./DashboardTable";
import BarChart from "./BarChart";

export const costFilterTypes = [
  {
    label: "KOL Sponsorship",
    value: "kolSponsorship",
  },
  {
    label: "sponsorship",
    value: "sponsorship",
  },
  {
    label: "KOL",
    value: "kol",
  },
];

const MediaResponseReport = () => {
  const [mediaResp, setMediaResp] = useState();

  const [mediaGraphR1, setMediaGraphR1] = useState([]);
  const [mediaGraphR2, setMediaGraphR2] = useState([]);
  const [optionsChartR1, setOptionsChartR1] = useState(null);
  const [optionsChartR2, setOptionsChartR2] = useState(null);

  const [rangeMonth, setRangeMonth] = useState({
    r1: [],
    r2: [],
  });

  const [show, setShow] = useState(false);
  const [detailDataResp, setDetailDataResp] = useState([]);
  const [isLoadingDataResp, setIsLoadingDataResp] = useState(true);
  const [selectedDetailData, setSelectedDetailData] = useState({
    typeName: "",
    type: "",
  });

  const [filterType, setFilterType] = useState("last6Months");
  const [filterData, setFilterData] = useState({
    date_range: "M",
    ...getFilterDates(filterType),
    type: "R",
  });
  const [inputFilterData, setInputFilterData] = useState({
    date_range: "M",
    ...getFilterDates(filterType),
    type: "R",
  });

  useEffect(() => {
    const firstRange = getMonthFromRange(
      filterData.r1_start_date,
      filterData.r1_end_date
    );
    const secondRange = getMonthFromRange(
      filterData.r2_start_date,
      filterData.r2_end_date
    );

    setRangeMonth({
      r1: firstRange,
      r2: secondRange,
    });

    setOptionsChartR1({
      xaxis: {
        categories: firstRange?.map(
          (m) => `${getShortMonth(m.month)} - ${m.year}`
        ),
      },
    });

    setOptionsChartR2({
      xaxis: {
        categories: secondRange?.map(
          (m) => `${getShortMonth(m.month)} - ${m.year}`
        ),
      },
      fill: {
        ...options.fill,
        colors: secondRange?.map((_, index) => getRandomColor(index)),
      },
    });
  }, [filterData]);

  const getComparisonOfMediaResp = async () => {
    const response = await getMediaResponseReport(filterData);
    console.log("media response report...", response);
    if (response.status !== "Fail") {
      setMediaResp(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };

  useEffect(() => {
    getComparisonOfMediaResp();
  }, [filterData]);

  useEffect(() => {
    const transformedDataR1 = mediaResp?.map((item) => {
      const r1Counts = [];

      rangeMonth.r1?.map((monthValue) => {
        r1Counts.push(
          getMonthValue(item.r1year, monthValue.year, monthValue.month + 1)
        );
        return false;
      });

      return {
        name: item.period,
        data: r1Counts,
      };
    });
    const transformedDataR2 = mediaResp?.map((item) => {
      const r2Counts = [];

      rangeMonth.r2?.map((monthValue) => {
        r2Counts.push(
          getMonthValue(item.r2year, monthValue.year, monthValue.month + 1)
        );
        return false;
      });

      return {
        name: item.period,
        data: r2Counts,
      };
    });

    setMediaGraphR1(transformedDataR1);
    setMediaGraphR2(transformedDataR2);
  }, [mediaResp]);

  const getMonthValue = (monthValues, year, month) => {
    const monthValue = monthValues.find(
      (m) => m.year === year && m.month === month
    );
    if (!monthValue) return 0;

    if (!monthValue.count) return 0;

    return monthValue.count;
  };

  const [options] = useState({
    chart: {
      type: "bar",
      // height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "65%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [],
    },
    yaxis: [
      {
        labels: {
          formatter: function (value) {
            return thousandAndDecimalFormat(value, true, 2);
          },
        },
      },
    ],
    fill: {
      opacity: 1,
      colors: [
        "#1F77B4",
        "#F16F33",
        "#9B9B9B",
        "#FFB628",
        "#2D69B9",
        "#65A345",
      ],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  });

  const { r1_start_date, r1_end_date, r2_start_date, r2_end_date } =
    inputFilterData;

  function areRangesEqualMonthCount(
    range1StartDate,
    range1EndDate,
    range2StartDate,
    range2EndDate
  ) {
    // Convert string dates to Date objects
    const r1Start = new Date(range1StartDate);
    const r1End = new Date(range1EndDate);
    const r2Start = new Date(range2StartDate);
    const r2End = new Date(range2EndDate);

    // Calculate the month count for both ranges
    const r1MonthCount =
      (r1End.getFullYear() - r1Start.getFullYear()) * 12 +
      (r1End.getMonth() - r1Start.getMonth());
    const r2MonthCount =
      (r2End.getFullYear() - r2Start.getFullYear()) * 12 +
      (r2End.getMonth() - r2Start.getMonth());

    // Check if both ranges have the same number of months
    return r1MonthCount === r2MonthCount;
  }

  const monthSame = areRangesEqualMonthCount(
    r1_start_date,
    r1_end_date,
    r2_start_date,
    r2_end_date
  );

  const handleApplyFilter = () => {
    if (!monthSame) {
      toastify("Both of range need to be match", "error");
    } else {
      setFilterData({
        ...inputFilterData,
      });
    }
  };

  const getTableDetailFunc = async () => {
    setDetailDataResp([]);
    setIsLoadingDataResp(true);
    const { type, typeName, ...rest } = selectedDetailData;
    const response = await getMediaResponseDetail({
      ...filterData,
      ...rest,
      type: selectedDetailData.type,
    });

    setIsLoadingDataResp(false);
    if (response.status !== "Fail") {
      setDetailDataResp(response?.data?.data);
    } else {
      toastify(response?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (selectedDetailData.type !== "" && selectedDetailData.typeName !== "") {
      getTableDetailFunc();
    }
  }, [selectedDetailData]);

  useEffect(() => {
    setInputFilterData((prevFilterData) => ({
      ...prevFilterData,
      ...getFilterDates(filterType),
    }));
  }, [filterType]);

  // useEffect(() => {
  //   setFilterData((prevFilterData) => ({
  //     ...prevFilterData,
  //     ...getFilterDates(filterType),
  //   }));
  // }, []);
  const [export_PDF, setexportPDF] = useState(false);

  const handleExportPdf = () => {
    setexportPDF(true);

    setTimeout(() => {
      exportPDF();
    }, 1000);
  };
  const exportPDF = () => {
    // have a specific div with an id 'exportArea'
    // that wraps around the elements you want to export.
    const input = document.getElementById("exportArea");
    html2canvas(input, {
      scale: 2,
    }) // scale: 2 to increase the resolution of the screenshot
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg", 0.7);

        // Initialize jsPDF
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm", // Set the unit to millimeters
          format: "a4", // Set the page format to A4
        });

        // Calculate the number of pages needed for the screenshot.
        const imgWidth = 180; // A4 size page width in mm
        const pageHeight = 297; // A4 size page height in mm
        const marginTop = 10; // Top margin
        const marginBottom = 10; // Bottom margin
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight + marginTop;

        let position = marginTop;

        // Add image to the first page.
        pdf.addImage(
          imgData,
          "JPEG",
          (pdf.internal.pageSize.width - imgWidth) / 2,
          position,
          imgWidth,
          imgHeight - 10
        );
        heightLeft -= pageHeight;

        // Add new pages if the screenshot spans over multiple pages.
        while (heightLeft >= 0) {
          position = marginTop - pageHeight - marginBottom;
          pdf.addPage();
          pdf.addImage(
            imgData,
            "JPEG",
            (pdf.internal.pageSize.width - imgWidth) / 2,
            position,
            imgWidth,
            imgHeight
          );
          heightLeft -= pageHeight;
        }

        // Save the PDF
        const date = new Date();

        pdf.save(`report_${date}.pdf`);
        setexportPDF(false);
      });
  };

  const newseriesYear = [];

  // Assuming both arrays have the same length
  for (let i = 0; i < mediaGraphR1?.length; i++) {
    newseriesYear.push(mediaGraphR1[i]);
  }

  // Assuming both arrays have the same length
  for (let i = 0; i < mediaGraphR2?.length; i++) {
    newseriesYear.push(mediaGraphR2[i]);
  }
  console.log(optionsChartR1?.xaxis?.categories);

  const combinedXaxes = [];
  for (let i = 0; i < optionsChartR1?.xaxis?.categories?.length; i++) {
    combinedXaxes.push(optionsChartR1?.xaxis?.categories[i]);
    combinedXaxes.push(optionsChartR2?.xaxis?.categories[i]);
  }

  const color1 = "#1F77B4";
  const color2 = "#21263c";
  const categoriesCount = combinedXaxes.length;
  const colors = Array.from({ length: categoriesCount }, (_, index) =>
    index % 2 === 0 ? color1 : color2
  );

  const newOptions = {
    xaxis: {
      categories: combinedXaxes,
      labels: {
        style: {
          colors: colors,
        },
      },
    },
    fill: {
      opacity: 1,
      colors: optionsChartR1?.fill?.colors,
    },
  };

  console.log(newOptions);

  const neaseriesYear = [];

  // Assuming both arrays have the same length and structure
  for (let i = 0; i < mediaGraphR1?.length; i++) {
    const mergedData = [];
    for (let j = 0; j < mediaGraphR1[i].data.length; j++) {
      mergedData.push(mediaGraphR1[i].data[j], mediaGraphR2[i].data[j]);
    }
    neaseriesYear.push({ name: mediaGraphR1[i].name, data: mergedData });
  }

  return (
    <div>
      <div className="d-flex flex-wrap gap-3 w-100 px-4 py-4">
        <div className="drop-down-box col-2 me-2">
          <div className="font-400-20 mb-1">Date Presets:</div>
          <SelectComponent
            options={filterTypes}
            selectedValue={filterType}
            singleSelect
            searchEnabled
            nullDisabled
            onChange={(e) => {
              setFilterType(e.value);
              setInputFilterData({ ...inputFilterData, date_range: e.value });
            }}
          />
        </div>
        <div className="range-1">
          <div className="font-400-20 mb-2">Range 1:</div>
          <div className="date-selector-box">
            <DateInput
              value={inputFilterData.r1_start_date}
              placeholder="Start Date"
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r1_start_date: formattedDate,
                });
              }}
            />
            -
            <DateInput
              placeholder="End Date"
              value={inputFilterData.r1_end_date}
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r1_end_date: formattedDate,
                });
              }}
            />
          </div>
        </div>
        <div className="range-2">
          <div className="font-400-20 mb-2">Range 2:</div>
          <div className="date-selector-box">
            <DateInput
              value={inputFilterData.r2_start_date}
              placeholder="Start Date"
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r2_start_date: formattedDate,
                });
              }}
            />
            -
            <DateInput
              placeholder="End Date"
              value={inputFilterData.r2_end_date}
              onChange={(e) => {
                const parsedDate = new Date(e);
                const formattedDate = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setInputFilterData({
                  ...inputFilterData,
                  r2_end_date: formattedDate,
                });
              }}
            />
          </div>
        </div>
        <Button
          className="addItem align-self-end"
          style={{ height: "47px" }}
          onClick={() => handleApplyFilter()}
        >
          Apply
        </Button>

        <Button
          className="addItem align-self-end"
          style={{ height: "47px" }}
          onClick={() => {
            handleExportPdf();
          }}
        >
          Export
        </Button>
      </div>
      <div className="age-block" id="exportArea">
        <div className="client-growth-block">
          {/* <h1 className="block-heading">Media Reports</h1> */}
          <div className="graphs-block">
            <div className="graph-item">
              <h2 className="year-title">
                {formatDate(filterData.r1_start_date)} -{" "}
                {formatDate(filterData.r1_end_date)}
                &nbsp;&nbsp;|&nbsp;&nbsp;
                {formatDate(filterData.r2_start_date)} -{" "}
                {formatDate(filterData.r2_end_date)}
              </h2>
              {newseriesYear && newOptions && (
                <BarChart
                  series={neaseriesYear}
                  options={{ ...options, ...newOptions }}
                />
              )}
            </div>
          </div>
        </div>

        <DashboardTable
          name="mediaResponse"
          filterData={filterData}
          rangeMonth={rangeMonth}
          values={mediaResp}
          firstField="year"
          secondField="year"
          setShow={setShow}
          setSelectedDetailData={setSelectedDetailData}
          export_pdf={export_PDF}
          showPercentage={true}
          showTotal={true}
        />
      </div>
      <Modal
        className=""
        size="lg"
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
      >
        <Modal.Header closeButton className="pb-0" style={{ borderWidth: 0 }}>
          <div className="d-flex w-100 justify-content-center p-0 alert-heading"
            style={{ fontSize: '1rem' }}
            >
              {selectedDetailData.typeName.charAt(0).toUpperCase() + selectedDetailData.typeName.slice(1)} From: {filterData.r1_start_date} - {filterData.r1_end_date}
              &nbsp;&nbsp;To:&nbsp;&nbsp;
              {filterData.r2_start_date} - {filterData.r2_end_date}
          </div>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "scroll", maxHeight: 400 }}>
          <div className="mode-table-block">
            <div className="table-header">
              <div className="table-col">
                <h3 className="col-title">Date</h3>
              </div>
              <div className="table-col">
                <h3 className="col-title">Patient Name</h3>
              </div>
              <div className="table-col">
                <h3 className="col-title">Source/Recommend By</h3>
              </div>
              <div className="table-col">
                <h3 className="col-title">Amount</h3>
              </div>
            </div>
            {!isLoadingDataResp ? (
              <>
                {detailDataResp?.map((item, index) => (
                  <div key={index} className="table-rows">
                    <div className="table-col">
                      <h3 className="col-title">
                        {formatDate(item.created_at)}
                      </h3>
                    </div>
                    <div className="table-col">
                      <h3 className="col-title">{item.fullName || "-"}</h3>
                    </div>
                    <div className="table-col">
                      <h3 className="col-title">{item.recommendFrom || "-"}</h3>
                    </div>
                    <div className="table-col">
                      <h3 className="col-title"> {item.totalAmount || "-"}</h3>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <span className="d-flex justify-content-center p-2"><CircularProgress /></span>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MediaResponseReport;
