import React, { useState, useEffect } from "react";
import { Accordion, Form, Modal } from "react-bootstrap";
import { PDFViewer } from "@react-pdf/renderer";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { getBillingByPatient, getBillings } from "../../../apis/billing";
import { getSetting } from "../../../apis/generalSetting";
import { toastify } from "../../../helperFunctions/toastify";
import InvoicePDF from "../../SharedComponents/InvoicePDF";
import Table from "react-bootstrap/Table";
import NoteRenderer from "../NoteTaking/NoteRenderer";
import { getMembershipCategories } from "../../../apis/membership";
import { getInvoiceId } from "../../../helperFunctions/utils";
import { authorizedRequests } from "../../../axios/axios-config";
import { thousandAndDecimalFormat } from "../../../utils/common";

export default function TabBillingHistory() {
  const patientData = useSelector((state) => state.patientData.value);
  const [billings, setBillings] = useState([]);
  // const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [activeIndex, setActiveIndex] = useState();
  const [setting, setSetting] = useState({});
  const [usePointVal, setUsePointVal] = useState(0);
  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedBilling, setSelectedBilling] = useState({});
  const [oriBillings, setOriBillings] = useState([]);
  // const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const getStatusPayment = (o) => {
    const paidAmount =
      o?.paymentRecord && o?.paymentRecord?.length > 0
        ? o?.paymentRecord[o?.paymentRecord?.length - 1].payed
        : 0;
    const gstAmount = o?.gst;

    if (paidAmount < gstAmount && paidAmount !== 0) {
      return "Outstanding";
    }

    if (o?.isPaymentCompleted === true) {
      return "Paid";
    }

    return "Unpaid";
  };

  const getBillingList = async () => {
    const { result, error } = await getBillingByPatient(patientData?._id);
    if (error) {
      if (error?.message === "No data Found") {
        setBillings([]);
      } else {
        toastify(error?.message, "error");
      }
      return;
    } else {
      let tempArr = result?.map((e) => {
        const loc = e?.branch?.location?.find((o) => o._id === e?.location);
        const packages = e?.package?.map((o) => {
          return {
            name: o?.name ? o?.name : o?.package_id?.name,
            qty: o?.package_qty,
            price: o?.package_unit_price,
            discount: o?.package_discount,
            discountType: o?.package_discountType,
            promoDiscountType: o?.promoDiscountType,
            promoDiscountValue: o?.promoDiscountValue,
            promoName: o?.promoName,
            total_price: o?.package_price,
          };
        });
        const product = e?.product?.map((o) => {
          return {
            name: o?.name ? o?.name : o?.product_id?.name,
            qty: o?.product_qty,
            price: o?.product_unit_price,
            discount: o?.product_discount,
            discountType: o?.product_discountType,
            promoDiscountType: o?.promoDiscountType,
            promoDiscountValue: o?.promoDiscountValue,
            promoName: o?.promoName,
            total_price: o?.product_price,
          };
        });
        const treatment = e?.treatment?.map((o) => {
          return {
            name: o?.name ? o?.name : o?.service_id?.name,
            qty: o?.service_qty,
            price: o?.service_unit_price,
            discount: o?.service_discount,
            discountType: o?.service_discountType,
            promoDiscountType: o?.promoDiscountType,
            promoDiscountValue: o?.promoDiscountValue,
            promoName: o?.promoName,
            total_price: o?.service_price,
          };
        });
        const others = e?.others?.map((o) => {
          return {
            name: o?.description,
            qty: 1,
            price: o?.total_price,
            discount: 0,
            total_price: o?.total_price,
          };
        });

        let taxVal = 0;
        let taxs = [];
        e?.tax?.map((tax) => {
          if (taxVal !== 0) {
            taxVal =
              tax.typeValue == "Percentage"
                ? (tax.value / 100) * taxVal
                : tax.value + taxVal;
          } else {
            taxVal =
              tax.typeValue == "Percentage"
                ? (tax.value / 100) * e?.actualAmount
                : tax.value + e?.actualAmount;
          }

          taxs.push({
            ...tax,
            afterTaxValue: taxVal,
          });
        });

        const notesObj = {
          startTime: e?.session?.startTime,
          endTime: e?.session?.endTime,
          staff: e?.session?.staff?.map((o) => o?.username),
          soap: e?.session?.patientSOAP,
          location: loc,
          service: [...packages, ...product, ...treatment, ...others],
          remarks: e?.remarks,
          usePoints: e?.usePoints,
          usePointAmount: e?.usePointAmount ? e?.usePointAmount : 0,
          promo: e?.promo,
          promoType: e?.promoType,
          promoDiscount: e?.promoDiscount,
          promoDiscountType: e?.promoDiscountType,
          paymentAdjustments: e?.paymentAdjustments,
          tax: e?.tax,
          promoDiscount:
            e?.promoType === "Amount"
              ? e?.promo
              : (e?.promo / 100) * e?.actualAmount,
          afterTaxValue: taxVal,
          gstAmount: e?.gst,
          actualAmount: e?.actualAmount,
          totalAmount: e?.totalAmount,
          totalItemAmount: e?.totalItemAmount,
          paymentMethod: e?.paymentMethod?.name,
          installmentRecord: e?.paymentRecord ?? [],
        };

        const statusPayment = getStatusPayment(e);
        let classNameBtn = "";
        if (statusPayment === "Paid") {
          classNameBtn = "green-rounded-button";
        } else if (statusPayment === "Outstanding") {
          classNameBtn = "yellow-rounded-button";
        } else {
          classNameBtn = "red-rounded-button";
        }

        return {
          date: new Date(e.date).toLocaleDateString(),
          notes: notesObj,
          _id: e._id,
          invoiceNo: e.invoiceNo,
          created_at: e?.created_at,
          remarks: e?.remarks,
          promo: e?.promo,
          promoType: e?.promoType,
          usePoints: e?.usePoints,
          actualAmount: e?.actualAmount,
          updated_at: e.updated_at,
          totalAmount: e.totalAmount,
          gstAmount: e?.gst,
          remark: e?.remarkDiscount,
          patient: e?.patient,
          isPaymentInInstallment: e?.isPaymentInInstallment,
          paymentRecord: e?.paymentRecord,
          paymentAdjustments: e?.paymentAdjustments,
          package: [...e?.package],
          others: [...e?.others],
          product: [...e?.product],
          treatment: [...e?.treatment],
          classNameBtn,
          status: statusPayment,
        };
      });
      tempArr = tempArr.reverse();
      setBillings(tempArr);
      setOriBillings(result.reverse());
    }
    // if (response?.status !== "Fail") {
    // 	// let _response = response?.filter(
    // 	// 	(e) => patientData?._id === e?.patient?._id,
    // 	// );

    // 	setBillings(_response);
    // 	console.log({ _response });
    // } else {

    // }
  };

  useEffect(() => {
    getSetting().then((res) => {
      if (res.status !== "Fail") {
        setSetting(res);
      }
    });
  }, []);

  useEffect(() => {
    authorizedRequests({
      url: "membershipSetting/listOfMembershipSetting",
      method: "get",
    }).then((res) => {
      setUsePointVal(res.result?.data[0]?.rewardPoint ?? 0);
    });
    getBillingList();
  }, []);

  return (
    <div className="billing-data-table">
      <Table striped bordered>
        <thead
          style={{
            background: "#24566f",
            color: "white",
          }}
        >
          <tr>
            <th className="wd-100">Invoice No</th>
            <th className="wd-100">Date</th>
            <th className="wd-100">Total Amount</th>
            <th className="wd-100">Total Amount (GST)</th>
            <th className="wd-150">Remark</th>
            <th className="wd-100">Status</th>
            <th className="ps-5 wd-100" style={{ minWidth: "150px" }}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {billings?.map((e, index) => (
            <>
              <tr>
                <td className="wd-150">
                  {e.invoiceNo ? getInvoiceId(e.invoiceNo) : "-"}
                </td>
                <td className="wd-100">
                  {new Date(e.date).toLocaleDateString()}
                </td>

                <td className="wd-100">
                  {thousandAndDecimalFormat(e.totalAmount, true, 0)}
                </td>

                <td className="wd-100">
                  {thousandAndDecimalFormat(e.gstAmount, true, 2)}
                </td>
                <td className="wd-100">
                  <div>{e.remark}</div>
                </td>
                <td className="wd-100">
                  <div className={e.classNameBtn}>{e.status}</div>
                </td>
                <td className="wd-100" style={{ minWidth: "200px" }}>
                  {e.status !== "Paid" && (
                    <button
                      className="addItem-billing"
                      style={{
                        display: "inline-block",
                        marginRight: "10px",
                        width: "auto",
                        padding: "0px 10px",
                        marginBottom: "5px"
                      }}
                      onClick={() => {
                        navigate("/add-payment", {
                          state: {
                            dataId: e._id,
                            invoiceNo: e.invoiceNo,
                            invoiceDate: new Date(e.date).toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                            }),
                            remarks: e.remarks,
                            promo: e.promo,
                            promoType: e.promoType,
                            usePoints: e.usePoints,
                            subtotalAmount: e.actualAmount,
                            totalAmount: e.totalAmount,
                            gstAmount: e.gstAmount,
                            patient: e.patient,
                            isPaymentInInstallment: e.isPaymentInInstallment,
                            paymentRecord: e.paymentRecord,
                            paymentAdjustments: e.paymentAdjustments,
                            package: [...e?.package],
                            others: [...e?.others],
                            product: [...e?.product],
                            treatment: [...e?.treatment],
                          },
                        });
                      }}
                    >
                      Payment
                    </button>
                  )}
                  <button
                    className="addItem-billing"
                    style={{
                      display: "inline-block",
                      marginRight: "10px",
                      width: "auto",
                      padding: "0px 10px",
                    }}
                    onClick={() => {
                      if (index === activeIndex) {
                        setActiveIndex();
                        setData();
                      } else {
                        setActiveIndex(index);
                        setData(e);
                      }
                    }}
                  >
                    Details
                  </button>
                  <button
                    className="addItem-billing"
                    style={{
                      display: "inline-block",
                      width: "auto",
                      padding: "0px 10px",
                    }}
                    onClick={() => {
                      setSelectedBilling(oriBillings[index]);
                      setShowInvoice(true);
                    }}
                  >
                    Print
                  </button>
                </td>
              </tr>
              {activeIndex === index && (
                <tr>
                  <td colSpan="7">
                    <NoteRenderer
                      role={"Doctor"}
                      isPatientBilling
                      patientProfile={patientData.patientProfile}
                      updated_at={e?.updated_at}
                      notes_data={e.notes}
                      date={e.date}
                      pointVal={usePointVal}
                      isBilling={true}
                    />
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </Table>

      {showInvoice && (
        <Modal
          centered
          show={showInvoice}
          onHide={() => {
            setShowInvoice(false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invoice
            </Modal.Title>
          </Modal.Header>
          <div className="pdf-btn">
            <PDFViewer style={{ width: "100%", height: "800px" }}>
              <InvoicePDF
                billing={selectedBilling}
                setting={setting}
                usePointVal={usePointVal}
              />
            </PDFViewer>
          </div>
        </Modal>
      )}
    </div>
  );
}
