import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toastify } from "../../../helperFunctions/toastify";
import { authorizedRequests } from "../../../axios/axios-config";
import {
  A_SS_Campaign,
  A_SS_Inventory,
  A_SS_KOL,
  A_SS_Patient,
  A_SS_Rostering,
  A_SS_Session,
  I_SS_Inventory,
  I_SS_KOL,
  I_SS_Patient,
  I_SS_Rostering,
  I_SS_Session,
  I_SS_Campaign,
  I_SS_NoteTaking,
  A_SS_Promo,
  I_SS_Promo,
  I_SS_Billing,
  A_SS_DataTable,
  I_SS_DataTable,
  A_SS_CustomizedReport,
  I_SS_CustomizedReport,
  I_SS_Appointment,
  I_SS_Question,
  A_SS_Question,
} from "../../../assets/images";
import { getVersion } from "../../../helperFunctions/utils";

const subMenuList = Object.freeze({
  APPOINTMENT: "appointment",
  MAILCHIMP: "mailchimp",
});

function SidebarStaff({ sidebar, setSidebar }) {
  const location = useLocation(); 
  const path = location.pathname;

  const [activePage, setActivePage] = useState();
  const [noteTaking, setNoteTaking] = useState(false);
  const [billing, setBilling] = useState(false);
  const [inventory, setInventory] = useState(false);
  const [recaption, setRecaption] = useState(false);
  const [reports, setReports] = useState(false);
  const [dashboards, setDashboards] = useState(false);
  const [questionnaire, setQuestionnaire] = useState(false);
  const [subMenu, setSubMenu] = useState({
    appointment: false,
    mailchimp: false,
  });

  const userData = useSelector((state) => state.userData.value);
  // console.log("userData..", userData);

  const handleSubMenu = (toOpen, value) => {
    setSubMenu({
      ...subMenu,
      appointment: toOpen === subMenuList.APPOINTMENT ? value : false,
      mailchimp: toOpen === subMenuList.MAILCHIMP ? value : false,
    });
  };

  window.addEventListener("click", function (e) {
    if (
      document.getElementById("clickbox")?.contains(e.target) ||
      document.getElementById("toggler")?.contains(e.target)
    ) {
    } else {
      let width =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      if (width < 820) {
        setSidebar(false);
      }
    }
  });

  const [categoriesList, setCategoriesList] = useState([]);

  const getCategoriesList = async () => {
    const { result, error } = await authorizedRequests({
      url: "category/listOfCategory",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      return;
    }

    if (result) {
      setCategoriesList(result?.data);
    } else {
      if (!result?.status === "Success") {
        setCategoriesList([]);
      } else {
        toastify(result?.message, "error");
      }
    }

    return;
  };

  useEffect(() => {
    getCategoriesList();
  }, []);
  
  const getReportLastUrl = () => {
    const lastValues = path.split("/category-report-view/");
    if (lastValues.length >= 2) {
      return decodeURIComponent(lastValues[1]);
    }
    return "";
  }

  const getLastUrl = () => {
    const lastSegment = path.split("/").pop();
    const decodedSegment = decodeURIComponent(lastSegment); 

    return decodedSegment;
  }

  return (
    <div
      id="clickbox"
      className={`custom-scroll sideBar ${
        sidebar ? "openSidebar" : "closeSidebar"
      }`}
    >
      <ul
        style={{
          overflow: "hidden",
          overflowY: "auto",
          minHeight: "850px",
        }}
      >
        {/* <Link
          to="/questions"
          onClick={() => {
            setActivePage("/questions");
            setNoteTaking();
          }}
        >
          <li className={activePage === "/questions" ? "active" : ""}>
            <i className="fa-solid fa-user"></i>
            Questionnaire Popup
          </li>
        </Link> */}

        {/* Start Recaption */}
        {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Reception"
        ) && (
          <div
            // to="/rostering"
            onClick={() => {
              setActivePage("/rostering");
            }}
          >
            <div className="sidebarExtend">
              <div className="w-100">
                <div
                  className={`d-flex align-items-center px-3 py-3 ${
                    recaption && "pb-0"
                  } pt-3`}
                  onClick={() => {
                    setRecaption(recaption ? null : "i");
                  }}
                >
                  <img alt="" src={I_SS_Inventory} className="me-3" />
                  Reception
                  <div className="ms-auto">
                    {recaption ? (
                      <i class="fa-solid fa-angle-up"></i>
                    ) : (
                      <i class="fa-solid fa-angle-down"></i>
                    )}
                  </div>
                </div>
                {recaption && (
                  <div className="py-1 my-2 gray-background">
                    <Link
                      to="/rostering"
                      onClick={(e) => {
                        setRecaption("Appointment");
                      }}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          recaption === "Appointment"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Appointment
                      </div>
                    </Link>
                    <Link
                      to="/session"
                      onClick={(e) => {
                        setRecaption("Session");
                      }}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          recaption === "Session"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Session
                      </div>
                    </Link>
                    <Link
                      to="/waiting-list"
                      onClick={(e) => {
                        setRecaption("Waiting List");
                      }}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          recaption === "Waiting List"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Waiting List
                      </div>
                    </Link>
                    <Link
                      to="/appointment-reminder"
                      onClick={() => setRecaption("Appointment Reminder")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          recaption === "Appointment Reminder"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Appointment Reminder
                      </div>
                    </Link>
                    <Link
                      to="/questions/new"
                      target="_blank"
                      onClick={() => window.open("/questions/new", "_blank")}
                      // onClick={() => setRecaption("Questionnaire")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          recaption === "Questionnaire"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Questionnaire
                      </div>
                    </Link>
                    <Link
                      to="/end-session-report"
                      onClick={(e) => {
                        setRecaption("End Session Report");
                      }}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          recaption === "End Session Report"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        End Session Report
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* End Recaption */}

        {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Patient-Database"
        ) && (
          <Link
            to="/patient"
            onClick={() => {
              setActivePage("/patient");
              handleSubMenu(subMenuList.APPOINTMENT, false);
              setNoteTaking();
              setBilling();
              setInventory();
            }}
          >
            <li
              className={
                activePage === "/patient" || activePage === "/add-patient"
                  ? "active"
                  : ""
              }
            >
              {activePage === "/patient" ? (
                <img alt="" src={A_SS_Patient} className="me-3" />
              ) : (
                <img alt="" src={I_SS_Patient} className="me-3" />
              )}
              Patient Database
            </li>
          </Link>
        )}

        {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Note-Taking"
        ) && (
          <div
            onClick={() => {
              setActivePage("/note-taking");
              // handleSubMenu(subMenuList.APPOINTMENT, false);
              // setBilling();
              // setInventory();
            }}
          >
            <div className="sidebarExtend">
              <div className="w-100">
                <div
                  className={`d-flex align-items-center px-3 py-3 ${
                    noteTaking && "pb-0"
                  } pt-3`}
                  onClick={() => {
                    setNoteTaking(noteTaking ? null : "d");
                  }}
                >
                  <img alt="" src={I_SS_NoteTaking} className="me-3" />
                  Note Taking
                  <div className="ms-auto">
                    {noteTaking ? (
                      <i class="fa-solid fa-angle-up"></i>
                    ) : (
                      <i class="fa-solid fa-angle-down"></i>
                    )}
                  </div>
                </div>
                {noteTaking && (
                  <div className="py-1 my-2 gray-background">
                    <Link
                      to="/doctor-notes"
                      onClick={() => setNoteTaking("doctor")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          noteTaking === "doctor"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Doctor
                      </div>
                    </Link>
                    <Link
                      to="/therapist-notes"
                      onClick={() => setNoteTaking("therapist")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          noteTaking === "therapist"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Therapist
                      </div>
                    </Link>
                    <Link
                      to="/nutritionist-notes"
                      onClick={() => setNoteTaking("nutritionist")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          noteTaking === "nutritionist"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Nutritionist
                      </div>
                    </Link>
                    {/* <Link
											to="/patient-notes"
											onClick={() =>
												setNoteTaking("patient")
											}
										>
											<div
												className={`sidebar-subcategory ps-5 py-2 ${
													noteTaking === "patient"
														? "white-background"
														: "gray-font"
												}`}
											>
												Nutritionist
											</div>
										</Link> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Finance"
        ) && (
          <div
            onClick={() => {
              setActivePage("/billing");
              // handleSubMenu(subMenuList.APPOINTMENT, false);
              // setNoteTaking();
              // setInventory();
            }}
          >
            <div className="sidebarExtend">
              <div className="w-100">
                <div
                  className={`d-flex align-items-center px-3 py-3 ${
                    billing && "pb-0"
                  } pt-3`}
                  onClick={() => {
                    setBilling(billing ? null : "b");
                  }}
                >
                  <img alt="" src={I_SS_Billing} className="me-3" />
                  Finance
                  <div className="ms-auto">
                    {billing ? (
                      <i class="fa-solid fa-angle-up"></i>
                    ) : (
                      <i class="fa-solid fa-angle-down"></i>
                    )}
                  </div>
                </div>
                {billing && (
                  <div className="py-1 my-2 gray-background">
                    <Link
                      to="/billing"
                      onClick={() => setBilling("listBilling")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          billing === "listBilling"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Invoice
                      </div>
                    </Link>
                    {/* <Link to="/refund" onClick={() => setBilling("Refund")}>
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          billing === "Refund"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Refund (Credit Note)
                      </div>
                    </Link> */}
                    <Link
                      to="/conversion"
                      onClick={() => setBilling("conversion")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          billing === "conversion"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Conversion / Refund
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Inventory"
        ) && (
          <div
            onClick={() => {
              setActivePage("/uom-inventory");
              // handleSubMenu(subMenuList.APPOINTMENT, false);
              // setNoteTaking();
              // setBilling();
            }}
          >
            <div className="sidebarExtend">
              <div className="w-100">
                <div
                  className={`d-flex align-items-center px-3 py-3 ${
                    inventory && "pb-0"
                  } pt-3`}
                  onClick={() => {
                    setInventory(inventory ? null : "i");
                  }}
                >
                  <img alt="" src={I_SS_Inventory} className="me-3" />
                  Inventory
                  <div className="ms-auto">
                    {inventory ? (
                      <i class="fa-solid fa-angle-up"></i>
                    ) : (
                      <i class="fa-solid fa-angle-down"></i>
                    )}
                  </div>
                </div>
                {inventory && (
                  <div className="py-1 my-2 gray-background">
                    <Link
                      to="/inventory-treatments"
                      onClick={() => setInventory("Treatments")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          inventory === "Treatments"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Treatments
                      </div>
                    </Link>
                    <Link
                      to="/packages"
                      onClick={() => setInventory("Packages")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          inventory === "Packages"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Packages
                      </div>
                    </Link>
                    <Link
                      to="/inventory-products"
                      onClick={() => setInventory("Products")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          inventory === "Products"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Products
                      </div>
                    </Link>
                    <Link
                      to="/adjustment-stock"
                      onClick={() => setInventory("Adjustment")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          inventory === "Adjustment"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Adjustment Stock
                      </div>
                    </Link>

                    <Link
                      to="/purchase-list"
                      onClick={() => setInventory("Purchase")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          inventory === "Purchase"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Purchase List
                      </div>
                    </Link>

                    <Link
                      to="/receiving-goods"
                      onClick={() => setInventory("Receive")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          inventory === "Receive"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Receiving Goods
                      </div>
                    </Link>

                    <Link
                      to="/vendor-contact"
                      onClick={() => setInventory("Vendor Contact")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          inventory === "Vendor Contact"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Vendor Contact
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Promo"
        ) && (
          <Link
            to="/promo"
            onClick={() => {
              setActivePage("/promo");
              handleSubMenu(subMenuList.APPOINTMENT, false);
              setNoteTaking();
              setBilling();
              setInventory();
            }}
          >
            <li className={activePage === "/promo" ? "active" : ""}>
              {activePage === "/promo" ? (
                <img alt="" src={A_SS_Promo} className="me-3" />
              ) : (
                <img alt="" src={I_SS_Promo} className="me-3" />
              )}
              Packages/Promo
            </li>
          </Link>
        )}

        {userData?.department?.permissions?.find(
          (e) => e.moduleName === "KOL/Channel"
        ) && (
          <Link
            to="/kol"
            onClick={() => {
              setActivePage("/kol");
              handleSubMenu(subMenuList.APPOINTMENT, false);
              setNoteTaking();
              setBilling();
              setInventory();
            }}
          >
            <li className={activePage === "/kol" ? "active" : ""}>
              {activePage === "/kol" ? (
                <img alt="" src={A_SS_KOL} className="me-3" />
              ) : (
                <img alt="" src={I_SS_KOL} className="me-3" />
              )}
              KOL/Channel
            </li>
          </Link>
        )}

        {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Human-Resource"
        ) && (
          <Link
            to="/hr"
            onClick={() => {
              setActivePage("/hr");
            }}
          >
            <li className={activePage === "/hr" ? "active" : ""}>
              {activePage === "/hr" ? (
                <img alt="" src={A_SS_DataTable} className="me-3" />
              ) : (
                <img alt="" src={I_SS_DataTable} className="me-3" />
              )}
              Human Resource
            </li>
          </Link>
        )}

        {/* {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Reports"
        ) && (
          <Link
            to="/data-table"
            onClick={() => {
              setActivePage("/data-table");
              handleSubMenu(subMenuList.APPOINTMENT, false);
              setNoteTaking();
              setBilling();
              setInventory();
            }}
          >
            <li className={activePage === "/data-table" ? "active" : ""}>
              {activePage === "/data-table" ? (
                <img alt="" src={A_SS_DataTable} className="me-3" />
              ) : (
                <img alt="" src={I_SS_DataTable} className="me-3" />
              )}
              Reports
            </li>
          </Link>
        )} */}

        {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Reports"
        ) && (
          <Link
            // to="/data-table"
            onClick={() => {
              // setActivePage("/data-table");
              handleSubMenu(subMenuList.APPOINTMENT, true);
              // setNoteTaking();
              // setBilling();
              // setInventory();
            }}
          >
            <div className="sidebarExtend">
              <div className="w-100">
                <div
                  className={`d-flex align-items-center px-3 py-3 ${
                    reports && "pb-0"
                  } pt-3`}
                  onClick={() => {
                    setReports(reports ? null : "b");
                  }}
                >
                  <img alt="" src={I_SS_Billing} className="me-3" />
                  Reports
                  <div className="ms-auto">
                    {reports ? (
                      <i class="fa-solid fa-angle-up"></i>
                    ) : (
                      <i class="fa-solid fa-angle-down"></i>
                    )}
                  </div>
                </div>
                {reports && (
                  <div className="py-1 my-2 gray-background">
                    {categoriesList?.map((item, index) => (
                      <Link
                        to={`/category-report-view/${item.name}`}
                        onClick={() => {}}
                      >
                        <div
                          className={`sidebar-subcategory ps-5 py-2 ${
                            getReportLastUrl() === item.name
                              ? "white-background"
                              : "gray-font"
                          }`}
                        >
                          {item?.name}
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Link>
        )}

        {/* {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Dashboards"
        ) && (
          <Link
            to="/customized-reports"
            onClick={() => {
              setActivePage("/customized-reports");
              setNoteTaking();
              handleSubMenu(subMenuList.APPOINTMENT, false);
              setInventory();
              setBilling();
            }}
          >
            <li
              className={activePage === "/customized-reports" ? "active" : ""}
            >
              {activePage === "/customized-reports" ? (
                <img alt="" src={A_SS_CustomizedReport} className="me-3" />
              ) : (
                <img alt="" src={I_SS_CustomizedReport} className="me-3" />
              )}
              Dashboards
            </li>
          </Link>
        )} */}

        {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Dashboards"
        ) && (
          <div
            onClick={() => {
              // setActivePage("/customized-reports");
              setNoteTaking();
              // handleSubMenu(subMenuList.APPOINTMENT, false);
              // setInventory();
              // setBilling();
            }}
          >
            <div className="sidebarExtend">
              <div className="w-100">
                <div
                  // to="/customized-reports"
                  className={`d-flex align-items-center px-3 py-3 ${
                    dashboards && "pb-0"
                  } pt-3`}
                  onClick={() => {
                    setDashboards(dashboards ? null : "d");
                  }}
                >
                  <img alt="" src={I_SS_CustomizedReport} className="me-3" />
                  Dashboard
                  <div className="ms-auto">
                    {dashboards ? (
                      <i class="fa-solid fa-angle-up"></i>
                    ) : (
                      <i class="fa-solid fa-angle-down"></i>
                    )}
                  </div>
                </div>
                {dashboards && (
                  <div className="py-1 my-2 gray-background">
                    <Link
                      to="/client-profile-reports"
                      onClick={() => setDashboards("dashboards")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          getLastUrl() === "client-profile-reports"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Client Profile
                      </div>
                    </Link>
                    {/* <Link
                      to="/daily-consolidate-sales-reports"
                      onClick={() => setDashboards("dashboards")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          dashboards === "dashboards"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Daily Consolidated Sales Report
                      </div>
                    </Link> */}
                    <Link
                      // to="/customized-reports"
                      to="/sales-reports"
                      onClick={() => setDashboards("dashboards")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          getLastUrl() === "sales-reports"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Sales
                      </div>
                    </Link>
                    <Link
                      to="/marketing-reports"
                      onClick={() => setDashboards("dashboards")}
                    >
                      <div
                        className={`sidebar-subcategory ps-5 py-2 ${
                          getLastUrl() === "marketing-reports"
                            ? "white-background"
                            : "gray-font"
                        }`}
                      >
                        Marketing
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Questionnaire"
        ) && (
          <Link
            to="/questions/new"
            target="_blank"
            onClick={() => {
              setActivePage("/questions/new");
              handleSubMenu(subMenuList.APPOINTMENT, false);
              setNoteTaking();
              setBilling();
              setInventory();
            }}
          >
            <li className={activePage === "/questions/new" ? "active" : ""}>
              {activePage === "/questions/new" ? (
                <img
                  alt=""
                  style={{ width: "17px" }}
                  src={A_SS_Question}
                  className="me-3"
                />
              ) : (
                <img
                  alt=""
                  style={{ width: "17px" }}
                  src={I_SS_Question}
                  className="me-3"
                />
              )}
              Questionnaire
            </li>
          </Link>
        )} */}

        {/* {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Appointment"
        ) && (
          <>
            <div
              onClick={() => {
                setActivePage("/appointment");
                // handleSubMenu(subMenuList.APPOINTMENT, true);
                setBilling();
                setInventory();
              }}
            >
              <div className="sidebarExtend">
                <div className="w-100">
                  <div
                    className={`d-flex align-items-center px-3 py-3 ${
                      noteTaking && "pb-0"
                    } pt-3`}
                    onClick={() => {
                      handleSubMenu(
                        subMenuList.APPOINTMENT,
                        !subMenu.appointment
                      );
                      setBilling();
                      setInventory();
                      setNoteTaking();
                    }}
                  >
                    <img alt="" src={I_SS_Appointment} className="me-3" />
                    Appointment
                    <div className="ms-auto">
                      {subMenu.appointment ? (
                        <i class="fa-solid fa-angle-up"></i>
                      ) : (
                        <i class="fa-solid fa-angle-down"></i>
                      )}
                    </div>
                  </div>
                  {subMenu.appointment && (
                    <div className="py-1 my-2 gray-background">
                      <Link
                        to="/appointments"
                        onClick={() =>
                          handleSubMenu(subMenuList.APPOINTMENT, "appointments")
                        }
                      >
                        <div
                          className={`sidebar-subcategory ps-5 py-2 ${
                            subMenu.appointment === "appointments"
                              ? "white-background"
                              : "gray-font"
                          }`}
                        >
                          Appointments
                        </div>
                      </Link>
                      <Link
                        to="/appointment-reminder"
                        onClick={() =>
                          handleSubMenu(
                            subMenuList.APPOINTMENT,
                            "appointment-reminder"
                          )
                        }
                      >
                        <div
                          className={`sidebar-subcategory ps-5 py-2 ${
                            subMenu.appointment === "appointment-reminder"
                              ? "white-background"
                              : "gray-font"
                          }`}
                        >
                          Appointment Reminder
                        </div>
                      </Link>
                      <Link
                        to="/waiting-list"
                        onClick={() =>
                          handleSubMenu(subMenuList.APPOINTMENT, "waiting-list")
                        }
                      >
                        <div
                          className={`sidebar-subcategory ps-5 py-2 ${
                            subMenu.appointment === "waiting-list"
                              ? "white-background"
                              : "gray-font"
                          }`}
                        >
                          Waiting List
                        </div>
                      </Link>
                      <Link
                        to="/patient-sms-reminder"
                        onClick={() =>
                          handleSubMenu(
                            subMenuList.APPOINTMENT,
                            "patient-sms-reminder"
                          )
                        }
                      >
                        <div
                          className={`sidebar-subcategory ps-5 py-2 ${
                            subMenu.appointment === "patient-sms-reminder"
                              ? "white-background"
                              : "gray-font"
                          }`}
                        >
                          Patient SMS Reminder
                        </div>
                      </Link>
                      <Link
                        to="/sms"
                        onClick={() =>
                          handleSubMenu(subMenuList.APPOINTMENT, "sms")
                        }
                      >
                        <div
                          className={`sidebar-subcategory ps-5 py-2 ${
                            subMenu.appointment === "sms"
                              ? "white-background"
                              : "gray-font"
                          }`}
                        >
                          SMS
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )} */}

        {/* {userData?.department?.permissions?.find((e) => e.moduleName === "Note-Taking") && ( */}

        {/* {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Session"
        ) && (
          <Link
            to="/session"
            onClick={() => {
              setActivePage("/session");
              handleSubMenu(subMenuList.APPOINTMENT, false);
              setNoteTaking();
              setBilling();
              setInventory();
            }}
          >
            <li className={activePage === "/session" ? "active" : ""}>
              {activePage === "/session" ? (
                <img alt="" src={A_SS_Session} className="me-3" />
              ) : (
                <img alt="" src={I_SS_Session} className="me-3" />
              )}
              Session
            </li>
          </Link>
        )} */}

        {/* {userData?.department?.permissions?.find(
          (e) => e.moduleName === "Inventory"
        ) && (
          <Link
            to="/uom-inventory"
            onClick={() => {
              setActivePage("/uom-inventory");
              setNoteTaking();
              setBilling();
            }}
          >
            <li className={activePage === "/uom-inventory" ? "active" : ""}>
              {activePage === "/uom-inventory" ? (
                <img alt="" src={A_SS_Inventory} className="me-3" />
              ) : (
                <img alt="" src={I_SS_Inventory} className="me-3" />
              )}
              Inventory
            </li>
          </Link>
        )} */}

        {/* {userData?.department?.permissions?.find(
					(e) => e.moduleName === "Campaign",
				) && (
					<Link
						to="/campaign"
						onClick={() => {
							setActivePage("/campaign");
							handleSubMenu(subMenuList.APPOINTMENT, false);
							setNoteTaking();
							setBilling();
							setInventory();
						}}
					>
						<li
							className={
								activePage === "/campaign" ? "active" : ""
							}
						>
							{activePage === "/campaign" ? (
								<img
									alt=""
									src={A_SS_Campaign}
									className="me-3"
								/>
							) : (
								<img
									alt=""
									src={I_SS_Campaign}
									className="me-3"
								/>
							)}
							Campaign
						</li>
					</Link>
				)} */}

        {/* <Link
          to="/testing-drawtool"
          onClick={() => {
            setActivePage("/data-table");
            setNoteTaking();
            setBilling();
          }}
        >
          <li className={activePage === "/data-table" ? "active" : ""}>
            {activePage === "/data-table" ? (
              <img alt="" src={A_SS_DataTable} className="me-3" />
            ) : (
              <img alt="" src={I_SS_DataTable} className="me-3" />
            )}
            Testing - Drawtool
          </li>
        </Link> */}

        {/* {userData?.department?.permissions?.find(
					(e) => e.moduleName === "Mail Chimp",
				) && (
					<>
						<div
							onClick={() => {
								setActivePage("/appointment");
								// handleSubMenu(subMenuList.APPOINTMENT, true);
								setBilling();
								setInventory();
							}}
						>
							<div className="sidebarExtend">
								<div className="w-100">
									<div
										className={`d-flex align-items-center px-3 py-3 ${
											noteTaking && "pb-0"
										} pt-3`}
										onClick={() => {
											handleSubMenu(
												subMenuList.MAILCHIMP,
												!subMenu.mailchimp,
											);
											setBilling();
											setInventory();
											setNoteTaking();
										}}
									>
										<img
											alt=""
											src={I_SS_Rostering}
											className="me-3"
										/>
										Mailchimp
										<div className="ms-auto">
											{subMenu.appointment ? (
												<i class="fa-solid fa-angle-up"></i>
											) : (
												<i class="fa-solid fa-angle-down"></i>
											)}
										</div>
									</div>
									{subMenu.mailchimp && (
										<div className="py-1 my-2 gray-background">
											<Link
												to="/create-templates"
												onClick={() =>
													handleSubMenu(
														subMenuList.MAILCHIMP,
														"email-templates",
													)
												}
											>
												<div
													className={`sidebar-subcategory ps-5 py-2 ${
														subMenu.mailchimp ===
														"email-templates"
															? "white-background"
															: "gray-font"
													}`}
												>
													Create Templates
												</div>
											</Link>
											<Link
												to="/send-mail"
												onClick={() =>
													handleSubMenu(
														subMenuList.MAILCHIMP,
														"mail-campaigns",
													)
												}
											>
												<div
													className={`sidebar-subcategory ps-5 py-2 ${
														subMenu.mailchimp ===
														"mail-campaigns"
															? "white-background"
															: "gray-font"
													}`}
												>
													Send Mail
												</div>
											</Link>
										</div>
									)}
								</div>
							</div>
						</div>
					</>
				)} */}

        {/* {userData?.department?.permissions?.find(
					(e) => e.moduleName === "Settings",
				) && (
					<Link
						to="/staff-settings"
						onClick={() => {
							setActivePage("/staff-settings");
							handleSubMenu(subMenuList.APPOINTMENT, false);
							setNoteTaking();
							setInventory();
							setBilling();
						}}
					>
						<li
							className={
								activePage === "/staff-settings" ? "active" : ""
							}
						>
							<i class="fa-solid fa-gear"></i>
							Settings
						</li>
					</Link>
				)} */}

        {/* <Link
					to="/draw-tool"
					onClick={() => {
						setActivePage("/draw-tool");
						handleSubMenu(subMenuList.APPOINTMENT, false);
						setNoteTaking();
						setInventory();
						setBilling();
					}}
				>
					<li className={activePage === "/draw-tool" ? "active" : ""}>
						<i class="fa-solid fa-gear"></i>
						Drawtool - Testing
					</li>
				</Link> */}
      </ul>
      <div style={{ textAlign: "center", color: "white" }}>
        <span>Version {getVersion()}</span>
      </div>
    </div>
  );
}

export default SidebarStaff;
