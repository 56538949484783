import { useState } from "react";
import "./marketing-style.scss";

import RevenueFromNewBusiness from "./RevenueFromNewBusiness";
import RevenueReport from "./RevenueReport";
import SalesPaymentMethod from "./SalesPaymentMethod";
import YearOverYear from "./YearOverYear";
import AverageCLV from "./AverageCLV";

export const filterTypes = [
  {
    label: "Date Range",
    value: "date",
  },
  {
    label: "By Month",
    value: "month",
  },
  {
    label: "By Quarter",
    value: "quarter",
  },
];
export const costFilterTypes = [
  {
    label: "KOL Sponsorship",
    value: "kolSponsorship",
  },
  {
    label: "sponsorship",
    value: "sponsorship",
  },
  {
    label: "KOL",
    value: "kol",
  },
];

const SalesReport = () => {
  const [selectedTab, setSelectedTab] = useState("Year-Over-Year");

  return (
    <div className="marketing-reports-page min-vh-100">
      <div className="px-4 py-2">
        <div className="mainHeading">
          <h1>Dashboard / Sales Report</h1>
        </div>
      </div>
      <div className="page-header col-9 d-flex px-4 w-100">
        <div className="sections-tabs">
          {/* <div
            className={`tab-item ${
              selectedTab === "revenueFromNewBusiness" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("revenueFromNewBusiness")}
          >
            Revenue From New Business %
          </div> */}
          <div
            className={`tab-item ${
              selectedTab === "Year-Over-Year" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("Year-Over-Year")}
          >
            Year-Over-Year Growth Revenue
          </div>
          <div
            className={`tab-item ${
              selectedTab === "averageCLV" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("averageCLV")}
          >
            Average CLV
          </div>
          <div
            className={`tab-item ${
              selectedTab === "revenue" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("revenue")}
          >
            Revenue
          </div>
          <div
            className={`tab-item ${
              selectedTab === "sales" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("sales")}
          >
            Sales Payment Method
          </div>
        </div>
      </div>
      <div className="content-block">
        {selectedTab === "revenueFromNewBusiness" ? (
          <RevenueFromNewBusiness />
        ) : selectedTab === "Year-Over-Year" ? (
          <YearOverYear />
        ) : selectedTab === "averageCLV" ? (
          <AverageCLV />
        ) : selectedTab === "revenue" ? (
          <RevenueReport />
        ) : selectedTab === "sales" ? (
          <SalesPaymentMethod />
        ) : null}
      </div>
    </div>
  );
};

export default SalesReport;
