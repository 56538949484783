import api from "../axios/axios-config";

export const hearAboutUs = async () => {
  try {
    let response = await api.get("hearAboutUs/listHearAboutUs", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getPatients = async (query) => {
  try {
    let response = await api.get("patient/listOfPatient", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: query,
    });
    return {
      list: response?.data?.data?.list,
      // list: response?.data?.data?.list?.reverse(),
      totalDoc: response?.data?.data?.totalDoc,
    };
  } catch (error) {
    return error?.response?.data;
  }
};

export const addPatients = async (
  data,
  status,
  isMember,
  receiveMarketingInfo
) => {
  console.log({ data, status, isMember, receiveMarketingInfo });
  let patientProfile = data;
  try {
    let response = await api.post(
      "patient/addPatient",
      {
        patientProfile,
        isMember,
        isActive: status,
        receiveMarketingInfo,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const modifyPatientCI = async (
  patientID,
  email,
  contactNo,
  preferredContact,
  country,
  city,
  address,
  postalCode
) => {
  let contactInfo = {
    email,
    contactNo,
    preferredContact,
    country,
    city,
    address,
    postalCode,
  };
  try {
    let response = await api.post(
      "patient/updatePatient",
      {
        patientID,
        contactInfo,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const modifyPatientEC = async (patientID, name, relation, contactNo) => {
  let emergencyContact = {
    name,
    relation,
    contactNo,
  };
  try {
    let response = await api.post(
      "patient/updatePatient",
      {
        patientID,
        emergencyContact,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const modifyPatientMembership = async (patientID, membershipInfo) => {
  try {
    let response = await api.post(
      "patient/updatePatient",
      {
        patientID,
        points: membershipInfo.points,
        pointsExpiry: membershipInfo.pointsExpiry,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const modifyPatientMI = async (patientID, medicalInfo) => {
  try {
    let response = await api.post(
      "patient/updatePatient",
      {
        patientID,
        medicalInfo,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const modifyPatientGI = async (
  patientID,
  apiData,
  status,
  isMember,
  receiveMarketingInfo
) => {
  try {
    let patientProfile = apiData;
    let response = await api.post(
      "patient/updatePatient",
      {
        patientID,
        patientProfile,
        isMember,
        receiveMarketingInfo,
        isActive: status,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deletePatient = async (patientID) => {
  try {
    let response = await api.post(
      "patient/updatePatient",
      {
        patientID,
        isDeleted: true,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const uploadCsv = async (file) => {
  try {
    let response = await api.post("cvsImport/importPatientFromCSV", file, {
      headers: {
        Authorization: localStorage.getItem("token"),
        "content-type": "multipart/form-data",
      },
    });

    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updatePatientProfileImage = async (patientID, file) => {
  try {
    let fd = new FormData();
    fd.append("image", file);
    fd.append("id", patientID);

    let response = await api.post("patient/updatePatientProfileImage", fd, {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getPatientsByDoctorId = async (doctorId) => {
  try {
    let response = await api.post(
      "users/listOfPatientByDocId",
      {
        doctorId,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getPatientDetailsById = async (patientID) => {
  try {
    let response = await api.post(
      "patient/getPatientDeatilsById",
      {
        patientID,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const loadOptionPatients = async (search, loadedOptions, { page, exceptPatientId }) => {
  const limit = 10;

  const params = {
    skip: page ? (page - 1) * limit : 0,
    limit: limit * page,
    "patientProfile.fullName": search,
    "patientProfile.contactNo": search,
    isForOption: true,
  };

  if (exceptPatientId) {
    params.exceptPatientId = exceptPatientId;
  }

  const response = await getPatients(params);
  const options = response?.list?.map((x) => ({
    value: x._id,
    label: x.patientProfile?.fullName,
    ...x,
  }));

  if (response.status === "Fail") {
    return {
      options: [],
      hasMore: false,
    };
  }

  return {
    options:
      page === 1
        ? [{ label: "Select All", value: "all" }, ...options]
        : options,
    hasMore: options.length === limit ? true : false,
    additional: {
      page: page ? page + 1 : 0,
    },
  };
};

const getReferrals = async (query) => {
  try {
    let response = await api.get("reports/listOfReferrals", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: query,
    });
    return {
      list: response?.data?.data?.list,
      totalDoc: response?.data?.data?.totalDoc,
    };
  } catch (error) {
    return error?.response?.data;
  }
};

export const loadOptionReferrals = async (search, loadedOptions, { page }) => {
  const limit = 10;

  const params = {
    skip: page ? (page - 1) * limit : 0,
    limit: limit,
    isForOption: true,
  };

  // Fetch the referrals based on the defined parameters
  const response = await getReferrals(params);

  // Map the response to the desired format
  const options = response?.list?.map((x) => ({
    value: x.recommend, // Use user ID or recommend ID
    label: x.userDetails?.username || "Unknown", // Use username or fallback to "Unknown"
    ...x,
  }));

  return {
    options: options || [], // Ensure options is an array, defaulting to empty if none found
    hasMore: options.length === limit, // Check if there are more options based on the limit
    additional: {
      page: page ? page + 1 : 1, // Increment the page number for the next request
    },
  };
};

export const loadSelectedPatient = async (value) => {
  const response = await getPatientDetailsById(value);

  return {
    label: response?.patientProfile?.fullName,
    value: response?._id,
  };
};

export const loadSelectedPatient2 = async (value) => {
  const isSelectAll = value?.some((item) => item.value === "all");

  // If "all" is selected, return the "Select All" option without calling the API
  if (isSelectAll) {
    return [
      {
        label: "All Patient",
        value: "all",
      },
    ];
  }
  const response = await getPatientDetailsById(value);

  if (response?.status === "Fail") {
    return [];
  }
  if (response?.length > 0) {
    let new_response = response?.map((a) => ({
      label: a?.patientProfile?.fullName,
      value: a?._id,
    }));
    return new_response;
  }
  return {
    label: response?.patientProfile?.fullName,
    value: response?._id,
  };
};
