import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "antd";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import { toastify } from "../../helperFunctions/toastify";
import { setTableSearch } from "../../redux/slices/commonSlice";

import DateInput from "./DateInput";

export default function FilterComponent(props) {
  const dispatch = useDispatch();

  const { tableSearch } = useSelector((state) => state.commonData);

  const [searchString, setSearchString] = useState(props.searchValue ? props.searchValue : "");
  const [selectedGender, setSelectedGender] = useState();
  const [selectedMember, setSelectedMember] = useState();
  const [active, setActive] = useState(props.searchRadio ? props.searchRadio : true);
  const [open, setOpen] = useState(false);
  const [tempArr, setTempArr] = useState([]);
  const [originalArr, setOriginalArr] = useState([]);
  const [tempOption, setTempOption] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const location = useLocation();

  const pathname = location.pathname;

  useMemo(() => {
    if (props?.array) {
      setTempArr([...props?.array]);
    }
  }, [props?.array]);

  useMemo(() => {
    if (props?.oriArray) {
      setOriginalArr([...props?.oriArray]);
    }
  }, [props?.oriArray]);

  useMemo(() => {
    if (props?.options && tempOption?.length == 0) {
      console.log(tempOption);
      const arr = props?.options?.map((o) => {
        return {
          ...o,
          isChecked: false,
        };
      });

      if (props.searchOption) {
        setTempOption([...props.searchOption]);
      } else {
        setTempOption([...arr]);
      }
    }
  }, [props?.options]);
  const checkEmpty = (arr1) => {
    if (arr1.length === 0) {
      toast.warning("No Results Found", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const submitSearch = () => {
    setOpen(false);
    let arr = [];

    if (searchString && searchString !== "") {
      if (props.value === "AccountNumber") {
        arr = props.array?.filter((e) =>
          e?.accountNumber?.includes(searchString)
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "PatientName") {
        arr = props.array?.filter((e) =>
          e?.patientProfile?.fullName
            ?.toLowerCase()
            ?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "ContactNo") {
        arr = props.array?.filter((e) =>
          e?.patientProfile?.contactNo
            ?.toLowerCase()
            ?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "QName") {
        arr = props.array?.filter((e) =>
          e?.question?.toLowerCase()?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "Username") {
        arr = props.array?.filter((e) =>
          e?.username?.toLowerCase()?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "NationalId") {
        arr = props.array?.filter((e) =>
          e?.patientProfile?.nationID?.includes(searchString)
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "StaffContact") {
        arr = props.array?.filter((e) =>
          e?.contactNumber?.includes(searchString)
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "Email") {
        arr = props.array?.filter((e) =>
          e?.email?.toLowerCase()?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "DotPhrases") {
        arr = props.array?.filter((e) =>
          e?.dot_phrases?.toLowerCase()?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "DotTitle") {
        arr = props.array?.filter((e) =>
          e?.title?.toLowerCase()?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "Address") {
        arr = props.array?.filter((e) =>
          e?.address?.toLowerCase()?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "Code") {
        arr = props.array?.filter((e) =>
          e?.code?.toLowerCase()?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "Name") {
        arr = props.array?.filter((e) =>
          e?.name?.toLowerCase()?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.value === "compName") {
        arr = props.array?.filter((e) =>
          e?.compName?.toLowerCase()?.includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props?.subArray) {
        arr = props.array?.filter((e) =>
          e[props?.value]?.find((o) =>
            o?.toLowerCase().includes(searchString.toLowerCase())
          )
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props?.subChild) {
        arr = props.array?.filter((e) =>
          e[props?.value][props.subChild]
            ?.toLowerCase()
            .includes(searchString.toLowerCase())
        );
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props?.isNested) {
        const key = props?.value;
        const keys = key.split(".");
        arr = props.array?.filter((e) => {
          let value = e;
          keys.forEach((k) => {
            value = value[k];
          });
          if (typeof value === "string" || typeof value === "number") {
            return value?.toLowerCase()?.includes(searchString.toLowerCase());
          } else if (typeof value == "boolean") {
            return JSON.stringify(value)
              ?.toLowerCase()
              ?.includes(searchString.toLowerCase());
          } else if (Array.isArray(value)) {
            if (props.withRef) {
              const tempArr = [...value];
              tempArr.forEach((o) => {
                const refKey = o;
                const refKeys = refKey.split(".");
                refKeys.forEach((k) => {
                  value = o[k];
                });
              });
            } else {
              return value?.find((o) => {
                return o?.toLowerCase().includes(searchString.toLowerCase());
              });
            }
          } else {
            return false;
          }
        });
        checkEmpty(arr);
        props.setArray(arr);
      } else if (props.dynamicFilter) {
        arr = props.array?.filter((e) => {
          if (
            typeof e[props?.value] === "string" ||
            typeof e[props?.value] === "number"
          ) {
            return e[props?.value]
              ?.toLowerCase()
              ?.includes(searchString.toLowerCase());
          } else if (typeof e[props?.value] == "boolean") {
            return JSON.stringify(e[props?.value])
              ?.toLowerCase()
              ?.includes(searchString.toLowerCase());
          } else if (Array.isArray(e[props?.value])) {
            console.log("called");
            return e[props?.value]?.find((o) =>
              o?.toLowerCase().includes(searchString.toLowerCase())
            );
          } else {
            return false;
          }
        });
        checkEmpty(arr);
        props.setArray(arr);
      } else {
        arr = props.array?.filter((e) => {
          return JSON.stringify(e[props?.value])
            ?.toLowerCase()
            ?.includes(searchString.toLowerCase());
        });
        checkEmpty(arr);
        props.setArray(arr);
      }

      if (arr.length > 0) {
        onSubmitRedux(props.value, searchString);
      }
    }
  };

  const submitCheckbox = () => {};

  const submitRadio = () => {
    setOpen(false);
    if (props.value === "Gender") {
      let arr = props.array?.filter(
        (e) => e?.patientProfile?.gender === selectedGender
      );
      props.setArray(arr);
      if (arr.length > 0) {
        onSubmitRedux(props.value, selectedGender);
      }
    } else if (props.value === "Member") {
      let arr = props.array?.filter(
        (e) => e?.isMember === (selectedMember === "Member" ? true : false)
      );
      props.setArray(arr);
      if (arr.length > 0) {
        onSubmitRedux(props.value, (selectedMember === "Member" ? true : false));
      }
    } else if (props.value === "Active") {
      let arr = props.array?.filter(
        (e) => e?.isMember.toString().toLowerCase() === active.toLowerCase()
      );
      props.setArray(arr);
      if (arr.length > 0) {
        onSubmitRedux(props.value, active.toLowerCase());
      }
    } else if (props.value === "QActive") {
      let arr = props.array?.filter(
        (e) => e?.isActive.toString().toLowerCase() === active.toLowerCase()
      );
      props.setArray(arr);
      if (arr.length > 0) {
        onSubmitRedux(props.value, active.toLowerCase());
      }
    } else if (props.value === "StaffRole") {
      let arr =
        props.array?.filter((e) => e?.role?.toString().includes(active)) || [];
      if (arr.length === 0) toastify(`No user with ${active} role`, "error");
      props.setArray(arr);
      if (arr.length > 0) {
        onSubmitRedux(props.value, active);
      }
    } else if (props.value === "QDescription") {
      let arr = props.array?.filter((e) => e?.questionType === active);
      props.setArray(arr);
      if (arr.length > 0) {
        onSubmitRedux(props.value, active);
      }
    } else if (props.value === "ProductType") {
      let arr = props.array?.filter((e) => e.type.name === active);
      props.setArray(arr);
      if (arr.length > 0) {
        onSubmitRedux(props.value, active);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Prevent the default action of the enter key (form submission)
      event.preventDefault();
      // Trigger the search action
      document.getElementsByClassName(`btn-search-${props.value}`)[0]?.click();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const onSubmitRedux = (type, value) => {
    const fieldPathname = pathname.replace("/", "");
    if (!fieldPathname.includes("report")) {
      dispatch(setTableSearch({
        ...tableSearch,
        [fieldPathname]: {
          currentDate: tableSearch[fieldPathname]?.currentDate ? tableSearch[fieldPathname]?.currentDate : new Date(),
          [type]: value
        }
      }))
    }
  }

  const onSubmitDateRedux = (value1, value2) => {
    const fieldPathname = pathname.replace("/", "");
    if (!fieldPathname.includes("report")) {
      dispatch(setTableSearch({
        ...tableSearch,
        [fieldPathname]: {
          startDate: value1,
          endDate: value2
        }
      }))
    }
  }

  const onResetRedux = (type) => {
    const fieldPathname = pathname.replace("/", "");
    if (!fieldPathname.includes("report")) {
      dispatch(setTableSearch({
        ...tableSearch,
        [fieldPathname]: {
          ...(tableSearch[fieldPathname] ? tableSearch[fieldPathname] : {}),
          [type]: null
        }
      }))
    }
  }

  const onResetDateRedux = () => {
    const fieldPathname = pathname.replace("/", "");
    if (!fieldPathname.includes("report")) {
      dispatch(setTableSearch({
        ...tableSearch,
        [fieldPathname]: {
          ...(tableSearch[fieldPathname] ? tableSearch[fieldPathname] : {}),
          startDate: null,
          endDate: null
        }
      }))
    }
  }

  const overlay = (
    <div
      className="ovly0 white-background p-3 d-flex overlay-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {props.type === "search" && (
        <div>
          <Form.Control
            placeholder="Search..."
            value={searchString}
            id="ovly35"
            onChange={(e) => setSearchString(e.target.value)}
          />
          <div className="d-flex mt-3">
            <button className={`small-button-1 me-2 btn-search-${props.value}`} onClick={submitSearch}>
              Search
            </button>
            <button
              className="small-button-2"
              onClick={() => {
                setSearchString("");
                props.setArray([...originalArr]);
                onResetRedux(props.value);
                setOpen(false);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      )}

      {props.type === "checkbox" && (
        <div>
          <div>
            <Form.Check
              label={<div className="font-12">Sort Item 1</div>}
              name="group1"
              type={"checkbox"}
            />
            <Form.Check
              label={<div className="font-12">Sort Item 2</div>}
              name="group1"
              type={"checkbox"}
            />
            <Form.Check
              label={<div className="font-12">Sort Item 3</div>}
              name="group1"
              type={"checkbox"}
            />
          </div>
          <div className="d-flex mt-3">
            <button className={`small-button-1 me-2 btn-search-${props.value}`} onClick={submitCheckbox}>
              Search
            </button>
            <button
              className="small-button-2"
              onClick={() => {
                setSearchString("");
                props.setArray([...originalArr]);
                setOpen(false);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      )}
      {props.type === "radio-search" && (
        <div>
          <div id="ovly21">
            {tempOption.map((e) => (
              <Form.Check
                label={<div className="font-12">{e.name}</div>}
                name="group1"
                checked={e.isChecked}
                type={"radio"}
                onChange={() => {
                  let tArr = [...props.array];
                  tArr = tArr?.filter((o) => o[props.value] == e.id);

                  let optArr = tempOption?.map((o) => {
                    if (o.id == e.id) {
                      return { ...o, isChecked: true };
                    }
                    return { ...o, isChecked: false };
                  });
                  setTempOption(optArr);
                  setTempArr(tArr);
                }}
              />
            ))}
          </div>
          <div className="d-flex mt-3">
            <button
              className={`small-button-1 me-2 btn-search-${props.value}`}
              onClick={() => {
                if (tempArr?.length == 0) {
                  toastify(
                    props?.message ?? "Results not found for applied filters",
                    "error"
                  );
                }
                props.setArray([...tempArr]);
                setTempOption((val) => val);

                if (tempArr?.length > 0) {
                  onSubmitRedux(props.value, tempOption);
                }

                // setTempArr(props.array);
                setOpen(false);
              }}
            >
              Search
            </button>
            <button
              className="small-button-2"
              onClick={() => {
                const arr = tempOption?.map((o) => {
                  return { ...o, isChecked: false };
                });
                setTempOption(arr);
                setOpen(false);
                setSearchString("");
                props.setArray([...originalArr]);
                setTempArr([]);
                onResetRedux(props.value);
                setActive(false);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      )}
      {props.type === "radio" && (
        <div>
          <div id="ovly21">
            {props.value === "Gender" &&
              props?.options?.map((e) => (
                <Form.Check
                  label={<div className="font-12">{e}</div>}
                  name="group1"
                  type={"radio"}
                  onChange={() =>
                    e === "Male"
                      ? setSelectedGender("Male")
                      : setSelectedGender("Female")
                  }
                  checked={selectedGender === e}
                />
              ))}
            {props.value === "Member" &&
              props?.options?.map((e) => (
                <Form.Check
                  label={<div className="font-12">{e}</div>}
                  name="group1"
                  type={"radio"}
                  onChange={() =>
                    e === "Member"
                      ? setSelectedMember("Member")
                      : setSelectedMember("Non Member")
                  }
                  checked={selectedMember === e}
                />
              ))}
            {props.value === "Active" &&
              props.options?.map((e) => (
                <Form.Check
                  label={<div className="font-12">{e}</div>}
                  name="group1"
                  type={"radio"}
                  onChange={() =>
                    e === "True" ? setActive("True") : setActive("False")
                  }
                  checked={active === e}
                />
              ))}

            {props.value === "QActive" &&
              props.options?.map((e) => (
                <Form.Check
                  label={<div className="font-12">{e}</div>}
                  name="group2"
                  type={"radio"}
                  onChange={() =>
                    e === "True" ? setActive("True") : setActive("False")
                  }
                  checked={active === e}
                />
              ))}

            {props.value === "StaffRole" &&
              props.options?.map((e) => (
                <Form.Check
                  label={<div className="font-12">{e}</div>}
                  name="group3"
                  type={"radio"}
                  onChange={() =>
                    e === "staff" ? setActive("staff") : setActive("admin")
                  }
                  checked={active === e}
                />
              ))}

            {props.value === "QDescription" &&
              props.options?.map((e) => (
                <Form.Check
                  label={<div className="font-12">{e}</div>}
                  name="group1"
                  type={"radio"}
                  onChange={() =>
                    e === "Yes/No"
                      ? setActive("Yes/No")
                      : e === "Multiple Indicators"
                      ? setActive("Multiple Indicators")
                      : setActive("Text")
                  }
                  checked={active === e}
                />
              ))}

            {props.value === "ProductType" &&
              props?.options?.map((e) => (
                <Form.Check
                  label={<div className="font-12">{e}</div>}
                  name="group1"
                  type={"radio"}
                  onChange={() => setActive(e)}
                  checked={active === e}
                />
              ))}
          </div>
          <div className="d-flex mt-3">
            <button className={`small-button-1 me-2 btn-search-${props.value}`} onClick={submitRadio}>
              Search
            </button>
            <button
              className="small-button-2"
              onClick={() => {
                setOpen(false);
                setSearchString("");
                props.setArray([...originalArr]);
                onResetRedux(props.value);
                setActive(false);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      )}
      {props.type === "date" && (
        <>
          <div>
            <b className="m-2">From:</b>
            <DateInput
              value={startDate}
              placeholder="Start Date"
              onChange={(e) => {
                const tempTime = e;
                tempTime.setHours(0);
                tempTime.setMinutes(0);
                tempTime.setSeconds(0);
                tempTime.setMilliseconds(0);

                setStartDate(e);
              }}
            />
            <b className="m-2">To:</b>
            <DateInput
              placeholder="End Date"
              className=""
              value={endDate}
              onChange={(e) => {
                if (!startDate) {
                  toastify("Please select start date", "error");
                  return;
                }
                if (e < startDate) {
                  toastify("End date can't be less than start date", "error");
                  return;
                }
                const tempTime = e;
                tempTime.setHours(23);
                tempTime.setMinutes(59);
                tempTime.setSeconds(59);
                setEndDate(tempTime);
              }}
            />
            <div className="d-flex mt-3">
              <button
                className={`small-button-1 me-2 btn-search-${props.value}`}
                onClick={() => {
                  let tempArr = props.array;
                  if (!startDate) {
                    toastify("Start Date is missing", "error");
                    return;
                  }
                  if (!endDate) {
                    toastify("End Date is missing", "error");
                    return;
                  }
                  if (props.isNested) {
                    tempArr = tempArr.filter(
                      (o) =>
                        new Date(o[props.isNested][props.value]).getTime() >=
                          new Date(startDate).getTime() &&
                        new Date(o[props.isNested][props.value]).getTime() <=
                          new Date(endDate).getTime()
                    );
                  } else {
                    tempArr = tempArr.filter(
                      (o) =>
                        new Date(o[props.value]).getTime() >=
                          new Date(startDate).getTime() &&
                        new Date(o[props.value]).getTime() <=
                          new Date(endDate).getTime()
                    );
                  }
                  checkEmpty(tempArr);
                  setOpen(false);
                  onSubmitDateRedux(startDate, endDate);

                  props.setArray(tempArr);
                }}
              >
                Search
              </button>
              <button
                className="small-button-2"
                onClick={() => {
                  props.setArray(props.array);
                  // setStartDate();
                  // setEndDate();
                  onResetDateRedux();
                  setOpen(false);
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );

  return (
    <Dropdown
      id="ovly22"
      open={open}
      trigger={["click"]}
      onClick={(e) => {
        e.stopPropagation();
      }}
      overlay={overlay}
    >
      <a onClick={() => setOpen(!open)}>
        <i class="fa-solid fa-arrow-down-short-wide cursor-pointer"></i>
      </a>
    </Dropdown>
  );
}
